import moment from 'moment/moment';
import {createSelector} from 'reselect';

// lodash
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import extend from 'lodash/extend';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

// file imports
import {labelSelector, reportingPermitRolesSelector} from '../../../app';
import errorTypes from '../../../../error/errorType';
import {caseInsensitiveSorter} from '../../../../utils/comparators';
import {createDeepEqualSelector} from '../../../../utils/selectorUtils';
import {
  COLUMN_COLID, INVESTMENT_POLICY, LOCKED_COLUMN_CONFIG, MAX_VIEWS,
  POLICYSCOPE_AND_FUNDNAME, REPORTING_FILTER_STATE, SHOW_ERROR_ON,
  SORT, VIEW_ACTION, REBATE_FIELDS, PORTFOLIO_FILTER_STATE
} from '../../../../constants/pageConstants';
import {FILE_DOWNLOAD_ORIGIN, FILE_DOWNLOAD_TYPES, REPORT_PAGE_IDS} from '../../../../constants/appConstants';
import {isInvestmentPolicyAllowedSelector} from '../../../user/index';
import translator from '../../../../services/translator';
import {pageIdSelector} from '../../index';
import {
  getFileDownloadSignaturesSelector,
  reportsConfigDataSelector,
  selectedSavedReportDataSelector
} from '../../../app/reports';
import {accountIdentifierPreference, fundIdentifierPreference} from '../../../preferences';
import {REPORTS_CONFIG, STANDARD_REPORT_VIEW_NAMES} from '../../../../containers/Reports/utils';
import {getJSDateFromString} from '../../../../utils/dateFormatter';
import {getProxyColumn, getSavedViewDetails, isCurrentBusinessDay, getAccountRelations} from '../../../../utils/reportingUtils';
import {getAggFunc} from '../../../../utils/portfolioGridFormatter';
import {allReportsGridDataSelector} from '../allReports';
import {entityFilters, additionalFiltersServiceFieldMapper} from '../../../../services/reports/common';
import {entitledToRebates} from '../../../containers/fundFactView'

const {translate: t} = translator;

const defaultTotalObj = {};
const defaultErrorObj = {};
const defaultFilterBy = [];
const defaultSelectedView = {};

const today = moment().toDate();
const oneWeek = moment().subtract(7, 'days').toDate();

// ADD selector only pagaData
export const groupColumns = (state) => state.pageData.groupColumns;
export const columnsMetadata = (state) => (state.appData.columnsMetadata && state.appData.columnsMetadata.columns) || [];
export const getReportFilters = (state) => (state.appData.reportFilters || []);
export const getReportsConfigData = (state) => (state.appData.reports || []);
export const isReportFiltersLoadedSelector = (state) => (state.appData.reportFiltersLoaded || false);
export const reportingAdditionalFilterConfigSelector = (state) => state.appData.reportingAdditionalFilterConfig || [];
// ADD selector only pageContext
export const reportResponse = (state) => state.pageContext.reportResponse;
export const pageInfo = (state) => state.pageContext.pageInfo;
export const getTotals = (state) => state.pageContext.totals || defaultTotalObj;
export const isLoading = (state) => state.pageContext.loading; // Don't assign any fallback
export const modelActiveMode = (state) => state.pageContext.modelActiveMode;
export const isModalLoading = (state) => state.pageContext.isModalLoading;
export const isFilterInvalid = (state) => state.pageContext.isFilterInvalid;
export const allViewsSelector = (state) => state.pageContext.views || [];
export const selectedViewId = (state) => state.pageContext.selectedViewId;
export const getFilters = (state) => state.pageContext.filterBy || defaultFilterBy;
export const getFilterModel = (state) => state.pageContext.filterModel || null;
export const getFilteredDataLength = state => state.pageContext.filteredDataLength;
export const isOpenCustomViewModal = (state) => state.pageContext.isOpenCustomViewModal || false;
export const filterVisibility = (state) => state.pageContext.filterVisibility || REPORTING_FILTER_STATE.HIDDEN;
export const selectCollapseState = (state) => state.preferences.report ? state.preferences.report.isCollapsedAll : false;
export const getBaseCcy = (state) => state.preferences.global && state.preferences.global.baseCcy;
export const firms = (state) => state.preferences.global && state.preferences.global.selectedFirms;
export const duplicateViewName = (state) => state.pageContext.duplicateViewName;
export const checkIsPageInErrorMode = (state) => state.pageContext.isPageInErrorMode || false;
export const getErrorObject = (state) => state.pageContext.errorObject || defaultErrorObj;
export const showErrorOn = (state) => (state.pageContext.errorObject && state.pageContext.errorObject.showErrorOn) || '';
export const exportExcel = (state) => state.pageContext.exportExcel;
export const dateRange = (state) => state.pageContext.dateRange;
export const getLastEvent = (state) => state.pageContext.reportData && state.pageContext.reportData.event;
export const selectedFilterDataSelector = (state) => state.appData.reports ? state.appData.reports.selectedFilterData : undefined;
export const selectedNodeMapSelector = (state) => state.appData.reports ? state.appData.reports.selectedNodeMap : undefined;
export const initialNodeMapSelector = (state) => state.appData.reports ? state.appData.reports.initialNodeMap : undefined;
export const shouldRefreshEntitlementFiltersSelector = (state) => state.appData.reports.shouldRefreshEntitlementFilters;
export const previousViewFiltersSelector = (state) => get(state.appData, 'previousViewFilters', {});
export const initialFilterDataSelector = (state) => state.appData.reports ? state.appData.reports.initialFilterData : undefined;
export const allowFeatureSelector = (state) => state.user && state.user.permissions && state.user.permissions.allowFeatures ? state.user.permissions.allowFeatures : [];
export const saveCustomReportPendingFlagSelector = (state) => state.pageContext.saveCustomReportPending;
export const getSelectedFileType = (state) => state.pageContext.selectedFileType;
export const getSelectedFitToPageType = state => state.pageContext.fitToPageType;
export const isHideReportRollBackSnackBarMsgSelector = (state) => state.pageContext.isHideReportRollBackSnackBarMsg || false;
export const isOtherReportDataLoadingSelector = state => {
  const {
    isReportPageLoading, isSavedReportsLoading, isReportFiltersEntityDataLoading
  } = state.pageData;
  return (isReportPageLoading || isSavedReportsLoading || isReportFiltersEntityDataLoading);
};

export const isReportGridDataLoadingSelector = createDeepEqualSelector(
  isLoading,
  isOtherReportDataLoadingSelector,
  (loading, isOtherReportDataLoadingSelector) => {
    return (loading || isOtherReportDataLoadingSelector);
  }
);

export const hasMorePagesSelector = createDeepEqualSelector(
  pageInfo,
  (pageData = {}) => {
    const {pageInfo = {}} = pageData;
    return pageInfo.hasMorePages;
  }
);

export const columnsMetadataSelector = createDeepEqualSelector(
  columnsMetadata,
  (metadata) => {
    if (!isEmpty(metadata)) {
      return metadata.reduce((obj, column) => {
        const {field} = column;
        obj[field] = column;
        return obj;
      }, {});
    }
    return {};
  }
);

export const gridDataErrorCode = createSelector(
  reportResponse,
  (reportResponse) => {
    if (reportResponse && reportResponse.errorCode) {
      return reportResponse.errorCode;
    }
    return '';
  }
);

export const groupByFieldSelector = (state) => {
  if (!state.pageContext.groupBy && state.pageContext.groupBy !== '') {
    return 'none';
  } else if (state.pageContext.groupBy === '') {
    return 'none';
  } else {
    return state.pageContext.groupBy;
  }
};

export const startExcelExport = createSelector(exportExcel, (exportExcel) => {
  return exportExcel;
});

export const getReportColumns = (reportConfig, view = {}) => {
  const isCurrentDateCols = isCurrentBusinessDay(view);
  const {columnConfig = {}} = reportConfig;
  const {
    currentDayColumns: {
      mandatory: currDayMandatoryColumns,
      optional: currDayOptionalColumns,
    } = {},
    defaultColumns: {
      mandatory: defaultMandatoryColumns,
      optional: defaultOptionalColumns,
    } = {},
    overrideColumns = []
  } = columnConfig;

  // If it's current date and current date configs was found
  const isCurrentDateConfigFound = isCurrentDateCols && !isEmpty(currDayMandatoryColumns);
  return {
    currDayMandatoryColumns: isCurrentDateConfigFound ? currDayMandatoryColumns : [],
    currDayOptionalColumns: isCurrentDateConfigFound ? currDayOptionalColumns : [],
    defaultMandatoryColumns: !isCurrentDateConfigFound ? defaultMandatoryColumns : [],
    defaultOptionalColumns: !isCurrentDateConfigFound ? defaultOptionalColumns : [],
    overrideColumns
  };
};

export const reportsConfigSelector = createSelector(
  reportsConfigDataSelector,
  (reportsConfigData) => {
    if (
      reportsConfigData &&
      reportsConfigData.reports &&
      reportsConfigData.reports.length
    ) {
      return reportsConfigData.reports;
    }
    return [];
  }
);

export const currentReportConfigSelector = createDeepEqualSelector(
  reportsConfigSelector,
  allViewsSelector,
  selectedSavedReportDataSelector,
  pageIdSelector,
  (reportsConfig, allViews = [], selectedSavedReportData, currentPageId) => {
    const {savedReportViewId} = selectedSavedReportData || {};
    const {savedViewPageId} = getSavedViewDetails(allViews, savedReportViewId);
    const pageId = savedViewPageId || currentPageId;
    return (reportsConfig && reportsConfig.find(({legacyReportId, newReportId}) => {
      return [legacyReportId, newReportId].includes(pageId);
    })) || {};
  }
);

export const currentReportIdSelector = createDeepEqualSelector(
  currentReportConfigSelector,
  (currentReportConfig = {}) => (currentReportConfig.newReportId)
);

export const currentViewSelector = createSelector(
  allViewsSelector,
  selectedSavedReportDataSelector,
  pageIdSelector,
  groupByFieldSelector,
  columnsMetadataSelector,
  currentReportConfigSelector,
  (
    allViews, selectedSavedReportData = {},
    currentPageId, groupBy, metadata,
    currentReportConfig
  ) => {
    const {savedReportViewId} = selectedSavedReportData || {};
    const standardReportName = STANDARD_REPORT_VIEW_NAMES[currentPageId];
    const findFunc = savedReportViewId ? (({isActive}) => {
      return isActive;
    }) : (({name, isActive}) => {
      return ((standardReportName === name) && isActive);
    });
    const matchedView = allViews.find(findFunc);
    const {columns = []} = matchedView || {};
    const currentReportColumns = getReportColumns(currentReportConfig, matchedView);
    const viewCols = [];
    const addViewColumn = (col, index) => {
      const viewCol = columns.find(({colId}) => colId === col);
      if (viewCol || metadata[col]) {
        const columnLookUp = viewCol || {...metadata[col]};
        const {
          field, colId, dataType, width, aggFunc, suppressSizeToFit,
          sort, groupColSpan, cellRenderer, hide, aggFieldKey, cellRendererKey, aggGroupColums
        } = columnLookUp;
        const column = {
          columnIndex: index,
          field,
          colId,
          dataType,
          ...(width ? {width} : {}),
          ...(sort ? {sort} : {}),
          ...(hide ? {hide} : {}),
          ...(cellRenderer ? {cellRenderer} : {}),
          ...(cellRendererKey ? {cellRendererKey} : {}),
          ...(suppressSizeToFit ? {suppressSizeToFit} : {}),
          ...(groupColSpan ? {groupColSpan} : {}),
          ...(aggFieldKey ? {aggFieldKey} : {}),
          ...(aggFunc ? {aggFunc} : {}),
          ...(aggGroupColums? {aggGroupColums} : {})
        };
        viewCols.push(column);
      }
    };

    // Add the remaining keys found in the mandatory columns list
    const {
      currDayMandatoryColumns = [], currDayOptionalColumns = [],
      defaultMandatoryColumns = [], defaultOptionalColumns = []
    } = currentReportColumns;
    const mandatoryColumns = !isEmpty(currDayMandatoryColumns) ?
      currDayMandatoryColumns : defaultMandatoryColumns;
    const optionalColumns = !isEmpty(currDayOptionalColumns) ?
      currDayOptionalColumns : defaultOptionalColumns;
    const allColumns = mandatoryColumns.concat(optionalColumns);
    const currentViewColumnKeys = columns
      .map(({field}) => field)
      .filter(field => ((field === COLUMN_COLID.GROUP) || allColumns.includes(field)));
    // Add the columns
    const mandatoryReportColKeys = difference(mandatoryColumns, currentViewColumnKeys);
    const sortedMandatoryReportColKeys = sortBy(mandatoryReportColKeys, (key) => {
      return mandatoryColumns.indexOf(key) || optionalColumns.indexOf(key);
    });
    const sortedReportColKeys = currentViewColumnKeys.concat(sortedMandatoryReportColKeys);
    const groupedColFound = sortedReportColKeys.some(key => key === COLUMN_COLID.GROUP);
    if (!groupedColFound) {
      sortedReportColKeys.unshift(COLUMN_COLID.GROUP);
    }
    if (sortedReportColKeys.length > 0) {
      let index = viewCols.length;
      sortedReportColKeys.forEach((column) => {
        addViewColumn(column, index);
        index += 1;
      });
    }
    return matchedView ? {...matchedView, columns: viewCols, groupBy} : {};
  }
);

export const getFileType = createSelector(
  getSelectedFileType, currentViewSelector, (fileType, currentView) => {
    return fileType || currentView.downloadFileType || FILE_DOWNLOAD_TYPES.EXCEL;
  }
);

export const getFitToPageType = createSelector(
  getSelectedFitToPageType, currentViewSelector, (fitToPage, currentView) => {
    return fitToPage || currentView.fitPdfToSinglePage || false
  }
);
export const isReportHasVariableColumnsSelector = createSelector(
  currentViewSelector,
  (currentView) => (
    currentView.baseView === 'ACCOUNTBALANCEREPORT' || currentView.baseView === 'FUNDBALANCEREPORT'
  )
);

export const pageIdForCurrentReportSelector = createSelector(
  currentViewSelector,
  (currentView) => {
    const {pageId} =  REPORTS_CONFIG[currentView.baseView] || {};
    return pageId;
  }
);

export const isIncludeAdditionalFilterAsPayloadSelector = createSelector(
  pageIdForCurrentReportSelector,
  (pageId) => (
    ['current-trade-totals', 'gain-loss-onshore', 'trade-approval-exceptions', 'ex-post-costs'].includes(pageId)
  )
);

export const checkForNoActiveView = createSelector(
  currentViewSelector,
  (currentView) => isEmpty(currentView)
);

export const currentViewColumnsSelector = createDeepEqualSelector(
  currentViewSelector,
  (view = {}) => {
    const {columns = []} = view;
    return columns;
  }
);

export const currentViewColumnMapper = createDeepEqualSelector(
  currentViewColumnsSelector,
  (columns = []) => {
    let columnMapper = {};
    if (!isEmpty(columns)) {
      columnMapper = columns.reduce((mapper, column) => {
        const {field} = column;
        mapper[field] = column;
        return mapper;
      }, {});
    }
    return columnMapper;
  }
);

export const getGroupColumns = createSelector(groupColumns, (columns) => {
  return (
    (columns &&
      columns.filter(
        (data) =>
          data.value !== POLICYSCOPE_AND_FUNDNAME.POLICYSCOPE_AND_FUNDNAME
      )) ||
    columns
  );
});

export const getGroupByFields = createDeepEqualSelector(
  currentReportConfigSelector,
  (currentReportConfig) => {
    const {controlPanelConfig: {groupByFields = []} = {}} =
    currentReportConfig || {};
    return groupByFields;
  }
);

export const groupByColumns = createDeepEqualSelector(
  getGroupByFields,
  currentViewSelector,
  (groupColumns, currentView) => {
    const {groupBy = ''} = currentView;
    return (
      groupColumns &&
      groupColumns.reduce((filtered, column) => {
        const groupByVal = column.value || 'none';
        filtered.push({
          value: column.value,
          label: column.label.toUpperCase(),
          selected: groupBy === groupByVal,
          nodeId: column.value,
          hide: column.hide,
        });
        return filtered;
      }, [])
    );
  }
);

export const getCalendarConfig = createDeepEqualSelector(
  currentReportConfigSelector,
  (currentReportConfig) => {
    const {controlPanelConfig: {calendarConfig} = {}} = currentReportConfig || {};
    return calendarConfig;
  }
);

export const filterConfigSelector = createDeepEqualSelector(
  currentReportConfigSelector,
  (currentReportConfig) => {
    const {controlPanelConfig: {filterConfig} = {}} = currentReportConfig || {};
    return filterConfig;
  }
);

export const filterSelector = createDeepEqualSelector(
  currentReportConfigSelector,
  (currentReportConfig) => {
    const {controlPanelConfig: {filter} = {}} = currentReportConfig || {};
    return filter;
  }
);

export const filterAdditionalCriteriaSelector = createDeepEqualSelector(
  filterSelector,
  (filterSelectorObj) => {
    const {additionalCriteria} = filterSelectorObj || {};
    return additionalCriteria;
  }
);

export const defaultAdditionalFiltersSelector = createDeepEqualSelector(
  filterConfigSelector,
  filterAdditionalCriteriaSelector,
  (filterConfig, filterSelectedValues) => {
    const {additionalCriteria: filterAdditionalCriteria} = filterConfig;
    const defaultAdditionalFilters = {};
    !isEmpty(filterAdditionalCriteria) && filterAdditionalCriteria
      .forEach((key) => {
        if (filterSelectedValues) {
          const selectedValues = filterSelectedValues[key] &&
            filterSelectedValues[key].selected || [];
          if (!isEmpty(selectedValues)) {
            defaultAdditionalFilters[key] = selectedValues;
          }
        }
      });
    return defaultAdditionalFilters;
  }
);

export const currentReportColumnsSelector = createDeepEqualSelector(
  currentReportConfigSelector,
  currentViewSelector,
  (currentReportConfig = {}, currentView = {}) => {
    return getReportColumns(currentReportConfig, currentView);
  }
);

export const selectedGroup = createDeepEqualSelector(
  groupByFieldSelector,
  groupByColumns,
  (selectedGroupValue, groupByColumns) => {
    const selectedGroup =
      groupByColumns &&
      groupByColumns.filter((group) => group.value === selectedGroupValue)[0];
    const selectedGroupLabel = (selectedGroup && selectedGroup.label) || '';
    return {
      value: selectedGroupValue,
      label: selectedGroupLabel,
    };
  }
);

export const selectedModelView = createSelector(
  allViewsSelector,
  selectedViewId,
  (allViews, id) => {
    if (VIEW_ACTION.id === id) {
      return allViews.find((view) => view.isActive);
    }
    return allViews.find((view) => view.id === id) || defaultSelectedView;
  }
);

export const metaDataSelector = createSelector(
    columnsMetadata,
    entitledToRebates,
    (metaData, entitledToRebates) => {
    const columnsWithRebatesCheck = !entitledToRebates && metaData.filter(
        data => !REBATE_FIELDS.includes(data.colId)
    ) || metaData;
  return columnsWithRebatesCheck.map((column) => {
    const columnData = {...column};
    columnData.headerTooltip = t(column.headerName);
    columnData.headerName = t(column.headerName);
    if (column.category) {
      columnData.category = t(column.category);
    }
    return columnData;
  });
});

export const getCurrentGridView = createSelector(
  currentReportColumnsSelector,
  currentViewColumnMapper,
  columnsMetadataSelector,
  labelSelector,
  accountIdentifierPreference,
  fundIdentifierPreference,
  (
    currentReportColumns = {},
    currentViewColumns = {},
    metadata,
    labels,
    preferredAccountIdentifier,
    preferredFundIdentifier
  ) => {
    const viewHeaders = [];
    const viewHeaderIndexMap = {};
    const {currDayMandatoryColumns, defaultMandatoryColumns, overrideColumns = []} = currentReportColumns;

    const addHeaderColumns = (column, index) => {
      if (metadata[column]) {
        const col = {...metadata[column]};
        let header = {...col, ...currentViewColumns[column]};

        // identify whether col should be overridden and update definition
        const overrideColumnIndex = findIndex(overrideColumns, {colId: header.colId});
        if (overrideColumnIndex !== -1) {
          header = {...header, ...overrideColumns[overrideColumnIndex]}
        }

        header = getProxyColumn(header, preferredAccountIdentifier, preferredFundIdentifier, metadata);
        const {headerName} = header;
        const label = labels[headerName] || headerName;
        if (header.width) {
          header.suppressSizeToFit = true;
        }
        header.headerName = label;
        if (header.sort === undefined || header.sort === 'none') {
          delete header.sort;
        }
        header.headerTooltip = label;
        viewHeaders.push(header);
        viewHeaderIndexMap[column] = index;
      }
    };

    const currentViewKeys = Object.keys(currentViewColumns);
    if (!isEmpty(currentViewKeys)) {
      // Add current view headers first
      currentViewKeys
        .forEach((column, index) => {
          addHeaderColumns(column, index);
        });
    }

    // Add the remaining keys found in the mandatory columns list
    const allMandatoryCols = currDayMandatoryColumns.concat(defaultMandatoryColumns);
    const diffKeys = difference(allMandatoryCols, currentViewKeys);
    if (diffKeys.length > 0) {
      let diffKeyIndex = viewHeaders.length;
      diffKeys.forEach((column) => {
        addHeaderColumns(column, diffKeyIndex);
        diffKeyIndex += 1;
      });
    }
    return viewHeaders;
  }
);

export const getGroupByColumnsForCurrentView = createDeepEqualSelector(
  currentViewColumnMapper,
  columnsMetadataSelector,
  groupByColumns,
  labelSelector,
  accountIdentifierPreference,
  fundIdentifierPreference,
  (
    currentViewColumns,
    metadata,
    groupByColumns,
    labels,
    preferredAccountIdentifier,
    preferredFundIdentifier
  ) => {
    const groupHeaders = [];
    const groupHeaderIndexMap = {};
    if (!isEmpty(currentViewColumns) && !isEmpty(metadata)) {
      const groupColumnObj = {};
      let index = 0;
      groupByColumns.forEach((groupColumn) => {
        const {value} = groupColumn;
        const columns = value.split('/');
        columns.forEach((column) => {
          if (isEmpty(column)) return;
          column = column.trim();
          if (groupColumnObj[column]) return;
          groupColumnObj[column] = 1;
          let obj = {};
          if (metadata[column]) {
            obj = {...metadata[column]};
          }
          if (currentViewColumns[column]) {
            obj = {...obj, ...currentViewColumns[column]};
          }
          obj = getProxyColumn(obj, preferredAccountIdentifier, preferredFundIdentifier, metadata);
          const {headerName} = obj;
          obj.hide = true;
          obj.rowGroup = false;
          obj.headerName = labels[headerName] || headerName;
          if (obj.sort === undefined || obj.sort === 'none') {
            delete obj.sort;
          }
          groupHeaderIndexMap[column] = index;
          index += 1;
          groupHeaders.push(cloneDeep(obj));
        });
      });
    }
    return {groupHeaders, groupHeaderIndexMap};
  }
);

export const columnSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) => {
    const sortedColumn = currentViewColumns.filter(
      (column) => column.sort === SORT.ASC || column.sort === SORT.DESC
    );
    if (sortedColumn.length > 1) {
      const column = sortedColumn.filter(
        (column) => column.colId !== COLUMN_COLID.GROUP
      ) || [{}];
      return column[0];
    }
    return sortedColumn[0] || {};
  }
);

export const preDefinedSortingState = createSelector(
  currentViewColumnsSelector,
  columnsMetadataSelector,
  accountIdentifierPreference,
  fundIdentifierPreference,
  (currentViewColumns, columnsMetadataWithKey, preferredAccountIdentifier, preferredFundIdentifier) => {
    const {colId: sortedColumnId, sort} = currentViewColumns.find(
      (column) => column.sort === SORT.ASC || column.sort === SORT.DESC
    ) || {};
    if (sortedColumnId) {
      const metaDataCol = columnsMetadataWithKey[sortedColumnId];
      if (metaDataCol.proxyColumn) {
        const accountIdentifierProxyColumn = metaDataCol.proxyMapping.find(
          (mapping) => mapping.dataKey === preferredAccountIdentifier
        );
        const fundIdentifierProxyColumn = metaDataCol.proxyMapping.find(
          (mapping) => mapping.dataKey === preferredFundIdentifier
        );
        const {field: preferredField} = accountIdentifierProxyColumn || fundIdentifierProxyColumn || {};
        const preferredColumn = columnsMetadataWithKey[preferredField];
        if (preferredColumn) {
          return [{...preferredColumn, sort}];
        }
      }
      return [{...metaDataCol, sort}];
    }
    return [];
  }
);

export const sortBySelector = createSelector(
  getCurrentGridView,
  (currentView) => {
    const coulmnsSortedArray = [];
    if (currentView.length > 0) {
      currentView.forEach((column) => {
        if (column.sort === SORT.ASC || column.sort === SORT.ASC) {
          coulmnsSortedArray.push({
            columnId: column.headerName,
            sort: column.sort,
          });
        }
      });
      return coulmnsSortedArray;
    } else {
      return [];
    }
  }
);

export const filterByMappedData = createSelector(
  getCurrentGridView,
  getFilters,
  (currentView, filterBy) => {
    const coulmnsFilteredArray = [];
    filterBy.forEach((filteredColumn) => {
      const columnFound = currentView.find(
        (column) => filteredColumn.field === column.colId
      );
      if (columnFound) {
        coulmnsFilteredArray.push({
          colId: columnFound.headerName,
          term: filteredColumn.term,
        });
      }
    });
    return coulmnsFilteredArray;
  }
);

export const columnSortingStateMappedData = createSelector(
  getCurrentGridView,
  columnSortingState,
  sortBySelector,
  (currentView, sortByState, sortBy) => {
    const mappedSortColumn = cloneDeep(sortByState);
    if (currentView.length > 0 && Object.keys(mappedSortColumn).length > 0) {
      const column = currentView.find(
        (column) => mappedSortColumn.colId === column.colId
      );
      if (column) {
        mappedSortColumn.colId = column.headerName;
      }
      return [mappedSortColumn];
    } else {
      return sortBy;
    }
  }
);

export const mapViewDataSelector = createSelector(
  allViewsSelector,
  (allViews) => {
    return allViews.map((view) => {
      return {
        value: view.id,
        label: view.name,
        selected: view.isActive,
        nodeId: view.id,
        isCustom: view.isCustom,
        report: view.report,
      };
    });
  }
);

export const customViewsSelector = createSelector(
  mapViewDataSelector,
  (allViews) => {
    return allViews.filter((view) => view.isCustom);
  }
);

export const presetViewsSelector = createSelector(
  mapViewDataSelector,
  (allViews) => {
    return allViews.filter((view) => !view.isCustom);
  }
);

const getFieldsFromGroupBy = createSelector(
  groupByFieldSelector,
  (groupByField = '') => {
    return groupByField.split('/').map((field) => field.trim());
  }
);

/** 
 * given page id, the selector is able to return whether 
 * it is a wire settlement or debit credit settlement view 
 */
export const isWireOrDebitCreditSettlementSelector = createSelector(currentReportIdSelector, (currentReportId) => {
  return [REPORT_PAGE_IDS.WS, REPORT_PAGE_IDS.DCSS].includes(currentReportId);
});

export const gridHeader = createSelector(
  getCurrentGridView,
  getGroupByColumnsForCurrentView,
  getFieldsFromGroupBy,
  columnsMetadataSelector,
  isWireOrDebitCreditSettlementSelector,
  (
    headerData = [],
    groupByColumns,
    groupByFields,
    allColumns = [],
    isWireOrDebitCreditSettlement
  ) => {
    if (! headerData.length) return [];
    const {groupHeaders, groupHeaderIndexMap} = groupByColumns;
    const groupColIndex = findIndex(
      headerData,
      (colDef) => colDef.colId === COLUMN_COLID.GROUP
    );
    if (groupColIndex !== -1 && groupByFields[0] !== 'none') {
      headerData[groupColIndex].groupFormatters = groupByFields.map(groupBy => {
        const columnDef = allColumns[groupBy] || {};
        return columnDef.formatters || '';
      });
    }
    for (let i = groupColIndex + 1; i < headerData.length; i++) {
      if (!headerData[i].hide && groupByFields[0] !== 'none') {
        headerData[i] = {...headerData[i], ...LOCKED_COLUMN_CONFIG};
        break;
      }
    }

    const newHeaders = [];
    groupByFields.forEach((groupName, index) => {
      const groupIndex = groupHeaderIndexMap[groupName];
      if (groupIndex !== undefined) {
        const groupHeaderMetadata = groupHeaders[groupIndex];
        const newGroupHeaderMetadata = {
          ...groupHeaderMetadata,
          rowGroupIndex: index,
          rowGroup: true
        };
        newHeaders.push(newGroupHeaderMetadata);
      }
    });
    headerData.forEach((item) => {
      const headeritem = {...item};
      const {field, columnIndex, groupColSpan} = item;
      const isNoGroupingSelected = (groupByFields[0] === 'none');
      const excludeGroupingColumn = isNoGroupingSelected && (field === COLUMN_COLID.GROUP);
      if (excludeGroupingColumn) {
        return;
      }
      headeritem.aggFunc = getAggFunc(item.aggFunc);
      headeritem.hide = item.hide || false;

      // group col span is set on DCSS and WS pages
      if (groupColSpan && isWireOrDebitCreditSettlement) {
        headeritem.colSpan = params => {
          const { node } = params;
          if (node && node.leafGroup) {
            return groupColSpan;
          }
        };
      }
      // Set the minwidth of Groupby column to 350
      if (!isNoGroupingSelected && (columnIndex === 1)) {
        newHeaders.push({...headeritem, minWidth: 310});
      } else {
        newHeaders.push(headeritem);
      }
    });

    return newHeaders;
  }
);

export const dropdownViewNamesSelector = createSelector(
  customViewsSelector,
  presetViewsSelector,
  isInvestmentPolicyAllowedSelector,
  labelSelector,
  (customViews, presetViews, isInvestmentPolicyAllowed, labels) => {
    const extPresetViews =
      (!isInvestmentPolicyAllowed &&
        presetViews.filter(
          (data) => data.label !== INVESTMENT_POLICY.INVESTMENT_POLICY
        )) ||
      presetViews;
    const totalViews = customViews.length + extPresetViews.length;
    const extendObj =
      totalViews >= 10 ? {} : {type: 'add', nodeId: 'add-view-portfolio'};
    const customView = extend(
      extendObj,
      totalViews >= 10 ? MAX_VIEWS : VIEW_ACTION
    ); // button to create a new view
    customView.label =
      labels[totalViews >= 10 ? MAX_VIEWS.name : VIEW_ACTION.name];
    let views = [];
    if (customViews.length > 0) {
      views = [...customViews.sort(caseInsensitiveSorter)];
      if (extPresetViews.length > 0) {
        views.push('-');
      }
      return [...views, ...extPresetViews.sort(caseInsensitiveSorter)];
    } else {
      return [...extPresetViews.sort(caseInsensitiveSorter)];
    }
  }
);

export const getCreateCustomViewCTALabel = createSelector(
  customViewsSelector,
  presetViewsSelector,
  labelSelector,
  (customViews, presetViews, labels) => {
    return labels[
      customViews.length + presetViews.length >= 10
        ? MAX_VIEWS.name
        : VIEW_ACTION.name
      ];
  }
);

export const uniqueColumnsSelector = createSelector(
  metaDataSelector,
  currentReportColumnsSelector,
  labelSelector,
  (metaDataColumns = [], currentReportColumns = {}, labels) => {
    const {
      currDayMandatoryColumns = [], currDayOptionalColumns = [],
      defaultMandatoryColumns = [], defaultOptionalColumns = []
    } = currentReportColumns;
    const mandatoryColumns = !isEmpty(currDayMandatoryColumns) ? currDayMandatoryColumns : defaultMandatoryColumns;
    const optionalColumns = !isEmpty(currDayOptionalColumns) ? currDayOptionalColumns : defaultOptionalColumns;
    const allCurrentReportColumns = mandatoryColumns.concat(optionalColumns);
    // Updated metadata after filtering mandatory columns
    const updatedMetadataColumns = metaDataColumns.filter(({field}) =>
      allCurrentReportColumns.includes(field)
    );
    const headers = [];
    updatedMetadataColumns.forEach(({field}) => {
      if (mandatoryColumns.includes(field)) {
        headers.push(labels.tkRequired);
      } else if (optionalColumns.includes(field)) {
        headers.push(labels.tkOptional);
      }
    });
    const uniqueHeaders = uniq(headers).sort();
    if (uniqueHeaders.indexOf('Required') !== -1) {
      uniqueHeaders.splice(uniqueHeaders.indexOf('Required'), 1);
      uniqueHeaders.unshift('Required');
    }
    return uniqueHeaders;
  }
);

const filteredMetaDataColumnsSelector = createDeepEqualSelector(
  metaDataSelector,
  currentReportColumnsSelector,
  currentViewSelector,
  (metaDataColumns, currentReportColumns = {}, currentView) => {
    const {
      currDayMandatoryColumns = [], currDayOptionalColumns = [],
      defaultMandatoryColumns = [], defaultOptionalColumns = []
    } = currentReportColumns;
    const mandatoryColumns = !isEmpty(currDayMandatoryColumns) ? currDayMandatoryColumns : defaultMandatoryColumns;
    const optionalColumns = !isEmpty(currDayOptionalColumns) ? currDayOptionalColumns : defaultOptionalColumns;
    const {columns = []} = currentView;
    const filteredMandatoryCols = mandatoryColumns
      .filter(col => {
        const hideColumn = columns.some(({field, hide}) => (col === field) && hide);
        return !hideColumn;
      });
    const allCurrentReportColumns = filteredMandatoryCols.concat(optionalColumns);
    // Updated metadata after filtering mandatory columns
    return metaDataColumns.filter(({field}) => allCurrentReportColumns.includes(field));
  }
);

export const groupedColumnsSelector = createDeepEqualSelector(
  uniqueColumnsSelector,
  filteredMetaDataColumnsSelector,
  currentReportColumnsSelector,
  currentViewSelector,
  (uniqueColumns, filteredMetaDataColumns, currentReportColumns = {}, currentView) => {
    const {
      currDayMandatoryColumns = [], currDayOptionalColumns = [],
      defaultMandatoryColumns = [], defaultOptionalColumns = [], overrideColumns = []
    } = currentReportColumns;
    const mandatoryColumns = !isEmpty(currDayMandatoryColumns) ? currDayMandatoryColumns : defaultMandatoryColumns;
    const optionalColumns = !isEmpty(currDayOptionalColumns) ? currDayOptionalColumns : defaultOptionalColumns;
    const sortedColumns = filteredMetaDataColumns.sort((a, b) => {
      return a.headerName.localeCompare(b.headerName);
    });
    const groupedColumns = uniqueColumns.map((uniqueColumn) => {
      const uniqueColumnObj = {category: uniqueColumn, headers: []};
      sortedColumns.forEach((column) => {
        if (column.hide) return;
        const isRequiredHeader = uniqueColumn.indexOf('Required') !== -1;
        const isRequiredField = isRequiredHeader && mandatoryColumns.includes(column.field);
        const isOptionalField = !isRequiredHeader && optionalColumns.includes(column.field);
        if (isRequiredField || isOptionalField) {
          // when column to be overriden, override overlapping cols
          const overrideColumn = find(overrideColumns, {colId: column.colId});
          if (overrideColumn ) {
            if (overrideColumn.hide) return;
            uniqueColumnObj.headers.push({
              ...column,
              ...overrideColumn,
             ...(overrideColumn.headerName ? {headerName: t(overrideColumn.headerName)} : {}),
            });
          } else {
            uniqueColumnObj.headers.push(column);
          }
        }
      });
      return uniqueColumnObj;
    });
    const {columns = []} = currentView;
    const currentViewFields = columns
      .filter(column => !column.hide)
      .map(column => column.field);
    const reportMandatoryFields = difference(mandatoryColumns, currentViewFields);
    const allReportFields = currentViewFields.concat(reportMandatoryFields);
    return groupedColumns.map((group) => {
      const groupCopy = {...group};
      groupCopy.headers = groupCopy.headers.map((header) => {
        const headerCopy = {...header};
        headerCopy.isChecked = allReportFields.indexOf(headerCopy.field) > -1;
        return headerCopy;
      });
      return groupCopy;
    });
  }
);

export const dataGetters = {
  formattedNameGetter: (data = {}, colConfig = {}) => {
    const [nameField] = colConfig.formatterFields;
    if (!nameField) return;
    const nameValue = data[nameField];
    if (!nameValue) {
      return (colConfig.colId === 'traderName') ? '' : t('tkNA2');
    }
    const [firstName, lastName] = nameValue.split(' ');
    if (!firstName || !lastName) return;
    return `${firstName.charAt(0)}. ${lastName}`;
  }
};


export const gridData = createSelector(
  reportResponse,
  getCurrentGridView,
  (reportResponse = {}, columns) => {
    const {reportData = []} = reportResponse;
    if (reportData.length > 0) {
      const uiDataGetter = columns.filter(col => col.uiDataGetter) || [];
      reportData.forEach(dataRow => {
        uiDataGetter.forEach(getter => {
          dataRow[getter.field] = dataGetters[getter.uiDataGetter](dataRow, getter);
        });
      });
      return reportData;
    }
    return reportData;
  }
);

export const getGridData = createSelector(
  gridData,
  groupByFieldSelector,
  labelSelector,
  (gridData) => {
    return (
      isArray(gridData) && gridData.map((rowData) => {
        return {...rowData};
      }) || []
    );
  }
);

export const disableFileDownloadSelector = createSelector(
  getGridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFileDownloadSignaturesSelector,
  (data, filteredDataLength, filterVisibility, filters  = [], files) => {
    const reportingFiles = files && files.filter(file => {
      return file.origin ===  FILE_DOWNLOAD_ORIGIN.REPORTING;
    }) || [];

    if (filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN) {
      return !data.length || reportingFiles.length > 0;
    } else {
      return (filters.length ? !filteredDataLength : !data.length) || reportingFiles.length > 0;
    }
  }
);


export const noRowDataSelector = createSelector(
  isLoading,
  labelSelector,
  getGridData,
  getFilters,
  isFilterInvalid,
  reportResponse,
  firms,
  getErrorObject,
  (
    loading,
    labels,
    getGridData,
    filters,
    invalidFilter,
    reportData,
    firms,
    errorObject
  ) => {
    if ((errorObject && errorObject.showErrorOn === SHOW_ERROR_ON.INSIDE_REPORTS_GRID) && (reportData && !reportData.errorCode)) {
      return labels[errorObject.errorMessage];
    } else if (invalidFilter) {
      return labels.tkCopyPort17;
    } else if (loading === undefined || (getGridData && getGridData.length)) {
      return undefined;
    } else if (filters.length) {
      return labels.tkCopyPort04;
    } else if ((firms && firms.length === 0) || (reportData && !reportData.errorCode)) {
      return labels.tkNoReportingAvailable;
    } else if (reportData && reportData.errorCode === errorTypes.TOO_MUCH_DATA) {
      return labels.tkCopyPort05;
    }
  }
);

export const viewName = createSelector(currentViewSelector, (currentView) => {
  return currentView.id || '';
});

export const getDateRange = createSelector(
  currentViewSelector,
  (currentView) => {
    const {date} = currentView;
    if (!date) {
      return {startDate: oneWeek, endDate: today};
    }
    const {fromDate, toDate, preset, maxDate} = date;
    const startDate = getJSDateFromString(fromDate);
    const endDate = getJSDateFromString(toDate);
    return {startDate, endDate, preset, maxDate};
  }
);

/*
 * File Download -  file ttypes optons
 *
 * */
const fileTypesOptions = [
  {
    id: FILE_DOWNLOAD_TYPES.EXCEL,
    label: 'tkXLS',
    value: FILE_DOWNLOAD_TYPES.EXCEL,
    isSelected: true
  },
  {
    id: FILE_DOWNLOAD_TYPES.PDF,
    label: 'tkPDF',
    value: FILE_DOWNLOAD_TYPES.PDF
  }
];

export const getFileTypes = createSelector(
  getFileType,
  (selectedFile) => {
    return fileTypesOptions.map((option) => {
      return {
        value: option.id,
        label: t(option.label),
        isSelected: selectedFile === option.id,
      };
    });
  }
);

export const getFiltersConfig = createSelector(() => ({
  filterId: 'reportName',
  onFilterChange: '',
  onFilterApply: '',
  filterList: [
    {
      headerName: '',
      field: 'firmAndBranches',
      component: 'CheckboxList',
      data: [],
      props: {
        type: 'tree',
      },
      placementType: 'column',
    },
  ],
}));

const getBranchId = ({id, code}) => {
  return `${id}-${code}`;
};

const getClientAccountName = ({clientAccountName, taAccount}) => {
  return clientAccountName ? taAccount : `{${taAccount}}`;
};

const getAccountIdentifier = ({taAccount, name, clientAccountName}, accountIdentifierPreference) => {
  if (accountIdentifierPreference === 'TA_ACCOUNT') {
    return taAccount;
  } else if (accountIdentifierPreference === 'ACCOUNT_NAME') {
    return name || `{${taAccount}}`;
  } else if (accountIdentifierPreference === 'ACCOUNT_ID') {
    return clientAccountName || `{${taAccount}}`;
  }
};

const getEntityLabel =({name, code}) => {
  const labelArr = [];
  if (code) labelArr.push(code);
  if (name) labelArr.push(name);

  return labelArr.join(' - ');
};

const getFirmNode = ({firmId, firmName, firmCode}, order) => {
  return {
    label: getEntityLabel({name: firmName, code: firmCode}),
    firmCode,
    order,
    value: firmId,
    id: firmId,
    key: 'firm'
  };
};

const getBranchNode = ({code, name}, firmId, branchId, order) => {
  return {
    firmId,
    order,
    id: branchId,
    key: 'branch',
    label: getEntityLabel({name, code}),
    value: branchId, // as all branch Id are not unique. branchCode - branch id is unique
  };
};

const getAccountNode = ({ clientAccountName, taAccount}, order, displayTaAccount) => {
  const clientName = getClientAccountName({ clientAccountName, taAccount });
  return {
    branchId: new Set(),
    id: clientName,
    label: displayTaAccount,
    value: clientName,
    key: 'account',
    order
  };
};

const getShareclassNode = ({ cusip, longName, nasdaqTicker, id, displayCode, shortName, taId }, order) => {
  return {
    id,
    label: getEntityLabel({name: longName || cusip || nasdaqTicker || '', code: displayCode || shortName || ''}),
    value: id,
    key: 'fund',
    order,
    taId
  };
};

const addAdditionalCriteria = (additionalCriteria, shareclass, account = {}, accountId) => {
  const {fundType, fundFamily, currencyCode, id} = shareclass;
  const {rep} = account;
  const obj = {shareclassId: id};
  if (accountId) {
    obj.accountId = accountId;
  }
  if (fundType) {
    const {fundTypes: acFundType} = additionalCriteria;
    const containsFundType = acFundType.find(ft => ft.code === fundType);
    if (containsFundType) {
      containsFundType.fundAccounts.push(obj);
      if (accountId) {
        containsFundType.accountIds.push(accountId);
      }
      containsFundType.shareclassIds.push(id);
    } else {
      const fundTypeObj = {
        code: fundType,
        description: fundType,
        accountIds: accountId ? [accountId] : [],
        shareclassIds: [id],
        fundAccounts: [obj],
        type: 'shareclass'
      };
      acFundType.push(fundTypeObj);
    }
  }

  if (fundFamily) {
    const {code = '', name = ''} = fundFamily;
    const {fundManagers: acFundFamily} = additionalCriteria;
    const containsFundFamily = acFundFamily.find(ff => ff.code === code);
    if (containsFundFamily) {
      containsFundFamily.fundAccounts.push(obj);
      if (accountId) {
        containsFundFamily.accountIds.push(accountId);
      }
      containsFundFamily.shareclassIds.push(id);
    } else {
      const fundFamilyObj = {
        code,
        description: name,
        accountIds: accountId ? [accountId] : [],
        shareclassIds: [id],
        fundAccounts: [obj],
        type: 'shareclass'
      };
      acFundFamily.push(fundFamilyObj);
    }
  }

  if (currencyCode) {
    const {currencies: acCurrencyCode} = additionalCriteria;
    const containsCurrencyCode = acCurrencyCode.find(cc => cc.code === currencyCode);
    if (containsCurrencyCode) {
      containsCurrencyCode.fundAccounts.push(obj);
      if (accountId) {
        containsCurrencyCode.accountIds.push(accountId);
      }
      containsCurrencyCode.shareclassIds.push(id);
    } else {
      const currencyCodeObj = {
        code: currencyCode,
        description: currencyCode,
        accountIds: accountId ? [accountId] : [],
        shareclassIds: [id],
        fundAccounts: [obj],
        type: 'shareclass'
      };
      acCurrencyCode.push(currencyCodeObj);
    }
  }

  if (rep && rep.code && rep.name) {
    const {reps: acReps} = additionalCriteria;
    const containsRep = acReps.find(r => r.code === rep.code);
    if (containsRep) {
      containsRep.fundAccounts.push(obj);
      if (accountId) {
        containsRep.accountIds.push(accountId);
      }
      containsRep.shareclassIds.push(id);
    } else {
      const repObj = {
        code: rep.code,
        description: rep.name,
        accountIds: [accountId],
        shareclassIds: [id],
        fundAccounts: [obj],
        type: 'account'
      };
      acReps.push(repObj);
    }
  }
};

const getInitialAdditionalCriteria = () => (
  entityFilters.reduce((final, item) => {
    final[item] = [];
    return final;
  }, {})
);

const sortFundManagersByAlphabeticalOrder = ({fundManagers}) => {
  fundManagers.sort((a, b) =>
    a.description.localeCompare(b.description)
  );
};

const addAllInAdditionalCriteria = ({fundManagers, fundTypes, currencies, reps}) => {
  fundManagers.unshift({code: 'all', description: 'All'});
  fundTypes.unshift({code: 'all', description: 'All'});
  currencies.unshift({code: 'all', description: 'All'});
  reps.unshift({code: 'all', description: 'All'});
};

const buildShareclassesWithoutAccount = (shareclassesWithoutAccounts, shareclassOrder, initShareclassNodeMap, allowedTaGroups, additionalCriteria) => {
  const shareclassValuesObj = {};
  const shareclassMap = {}
  const shareclassNodeMap = shareclassesWithoutAccounts.reduce((final, shareclass, index) => {
    const order = shareclassOrder + index;
    const { id } = shareclass;
    if (initShareclassNodeMap[id]) {
      console.log("Shareclass Id already attach to Account = ", id);
    }
    // do not skip if we have not seen this share class and the ta ids list include the share class taId
    else if (!final[id] && (isEmpty(allowedTaGroups) || allowedTaGroups.includes(shareclass.taId))) {
      const shareclassNodeValue = {...getShareclassNode(shareclass, order), unfunded: true };
      shareclassValuesObj[order] = {
        order,
        label: shareclassNodeValue.label,
        value: id,
        unfunded: true,
        taId: shareclassNodeValue.taId
      }
      shareclassMap[id] = getAccountRelations();
      addAdditionalCriteria(additionalCriteria, shareclass);
      final[id] = shareclassNodeValue;
    }

    return final;
  }, {});
  return {
    shareclassMap,
    shareclassNodeMap,
    shareclassValuesObj
  }
}

/**
 * selector gets the current view's entitlement filters which contain taGroup
 */
export const currentViewEntitlementFiltersSelector = createSelector(
  currentReportConfigSelector, currentViewConfig => currentViewConfig.entitlementFilters
);

export const currentViewHasEntitlementFiltersSelector = createSelector(
  currentViewEntitlementFiltersSelector, (currViewEntitlementFilters) =>
  !isEmpty(get(currViewEntitlementFilters, 'taGroups', []))
);

export const entitlementSelector = createSelector(
  getReportFilters,
  isReportFiltersLoadedSelector,
  currentViewEntitlementFiltersSelector,
  accountIdentifierPreference,
  // this arg is passed only when on saved report with restrictions
  (_, savedViewEntitlementFilters) => savedViewEntitlementFilters,
  (entitlements = {}, isReportFilterLoaded, entitlementFilters, accountIdentifierPreference, savedViewEntitlementFilters) => {
    if (isReportFilterLoaded && Object.keys(entitlements).length > 0 ) {
      const firmNodeMap = {};
      const branchNodeMap = {};
      const taAccountNodeMap = {};
      const shareclassNodeMap = {};

      const firmMap = {};
      const branchMap = {};
      const taAccountMap = {};
      const shareclassMap = {};
      const accountMap = {};

      const firmBranchValuesObj = {};
      const taAccountValuesObj = {};
      const shareclassValuesObj = {};

      let additionalCriteria = getInitialAdditionalCriteria();
      const {firmsAndBranches = [], shareclassesWithoutAccounts = []} = entitlements;
      let allowedTaGroups = get(entitlementFilters, 'taGroups', []);

      // if saved view entitlements defined and no restrictions on current view,
      // deduce that we are on saved report with restrictions
      if (isEmpty(allowedTaGroups) && isNil(entitlementFilters) && !isNil(savedViewEntitlementFilters)) {
        allowedTaGroups = get(savedViewEntitlementFilters, 'taGroups', []);
      }

      const entitlementsData = [];
      let shareclassOrder = 0;
      let accountOrder = 0;
      let firmOrder = 0;
      let branchOrder = 0;
      firmsAndBranches.forEach((firm) => {
        const { firmId, branches: firmBranches = [], taId } = firm;
        if(!isEmpty(allowedTaGroups) && !allowedTaGroups.includes(taId)){
          return;
        }
        const firmNode = getFirmNode(firm, firmOrder);
        if (! firmMap[firmId]) {
          firmNodeMap[firmId] = firmNode;
          firmMap[firmId] = getAccountRelations();
          firmBranchValuesObj[firmOrder] = {
            label: firmNode.label,
            value: firmId,
            children: [],
            order: firmOrder
          };
        }

        const children = [];
        firmBranches.forEach(branch => {
          const { accounts } = branch;
          const branchId = getBranchId(branch);
          const node = getBranchNode(branch, firmId, branchId, branchOrder);

          if (! branchMap[branchId]) {
            branchNodeMap[branchId] = {...node};
            branchMap[branchId] = getAccountRelations();
            children.push({ label: node.label, value: branchId, order: branchOrder });
            branchOrder += 1;
          }

          accounts.forEach(acct => {
            const accountId = get(acct, 'id');
            const shareclass = get(acct, 'shareclass', {});

            // Skip if shareclass is empty or current taId is not in the filter
            if (isEmpty(shareclass)) {
              return;
            }
            // using clientAccountName instead of taAccount to distinguish accounts which have same taAccount
            // but one has clientAccountName and the other does not
            const clientAccountName = getClientAccountName(acct);
            const displayTaAccount = getAccountIdentifier(acct, accountIdentifierPreference);
            const shareclassId = shareclass.id;
            if (! taAccountMap[clientAccountName]) {
              const node = getAccountNode(acct, accountOrder, displayTaAccount);
              taAccountValuesObj[accountOrder] = {
                label: displayTaAccount,
                value: clientAccountName,
                order: accountOrder
              };
              accountOrder += 1;
              taAccountNodeMap[clientAccountName] = {...node};
              taAccountMap[clientAccountName] = getAccountRelations();
            }

            if (! shareclassMap[shareclassId]) {
              const shareclassNodeValue = getShareclassNode(shareclass, shareclassOrder);
              shareclassValuesObj[shareclassOrder] = {
                label: shareclassNodeValue.label,
                value: shareclassId,
                order: shareclassOrder,
                taId: shareclassNodeValue.taId
              };
              shareclassOrder += 1;
              shareclassNodeMap[shareclassId] = {...shareclassNodeValue};
              shareclassMap[shareclassId] = getAccountRelations();
            }

            if (accountMap[accountId]) {
              console.log(`Duplicate Account Id = ${accountId} for firm Id = ${accountMap[accountId].firmId} branch Id = ${accountMap[accountId].branchId}`);
            }

            accountMap[accountId] = {
              firmId, branchId, shareclassId,
              accountId, taAccountId: clientAccountName
            };
            firmMap[firmId].accountIds[accountId] = 1;
            branchMap[branchId].accountIds[accountId] = 1;
            taAccountMap[clientAccountName].accountIds[accountId] = 1;
            shareclassMap[shareclassId].accountIds[accountId] = 1;

            const obj = {
              firmId, branchId, accountId,
              taAccountId: clientAccountName, shareclassId
            };
            entitlementsData.push(obj);
            addAdditionalCriteria(additionalCriteria, shareclass, acct, accountId);
          });
        });
        firmBranchValuesObj[firmOrder].children = [...children];
        firmOrder += 1;
      });
      if(Object.keys(firmNodeMap).length > 0) {
        sortFundManagersByAlphabeticalOrder(additionalCriteria);
        addAllInAdditionalCriteria(additionalCriteria);
      } else{
        additionalCriteria = {};
      }
      const scWithoutAcct = buildShareclassesWithoutAccount(shareclassesWithoutAccounts, shareclassOrder, shareclassNodeMap, allowedTaGroups, additionalCriteria);

      return {
        nodeMap: {
          firmNodeMap,
          branchNodeMap,
          taAccountNodeMap,
          shareclassNodeMap,
          firmMap,
          branchMap,
          taAccountMap,
          shareclassMap,
          accountMap,
          firmBranchValuesObj,
          taAccountValuesObj,
          shareclassValuesObj,
        },
        scWithoutAcct,
        additionalCriteria,
        entitlementsData
      };
    }
    return {};
  }
);

const isItemSelected = (selectedValues, itemCode) => {
  if (Array.isArray(itemCode)) {
    return itemCode.some(code => selectedValues.includes(code));
  }
  return selectedValues.some(val => val === itemCode);
};

const isAllItemsSelected = (additionalFilterConfig, selectedValues) => {
  if (isEmpty(selectedValues)) {
    return true;
  }
  if (additionalFilterConfig) {
    return additionalFilterConfig.items.filter(({code}) =>
      Array.isArray(code) || !(code.toLowerCase() === 'all')
    )
    .every(({code}) =>
      isItemSelected(selectedValues, code)
    );
  }
  return false;
};

export const additionalCriteriaFilterSelector = createDeepEqualSelector(
  entitlementSelector,
  filterConfigSelector,
  reportingAdditionalFilterConfigSelector,
  filterAdditionalCriteriaSelector,
  selectedFilterDataSelector,
  isIncludeAdditionalFilterAsPayloadSelector,
  (
    entitledData,
    filterConfig = {},
    reportingAdditionalFilterConfig,
    defaultSelectedValues = {},
    selectedFilterData = {},
    isIncludeAdditionalFilterAsPayload
  ) => {
    const formatDefaultSelectedValues = (selectedValues) => {
      return Object.keys(selectedValues).reduce((final, key) => {
        final[key] = selectedValues[key].selected;
        return final;
      }, {});
    };
    const {additionalCriteria} = entitledData;
    const {additionalCriteria: filterAdditionalCriteria} = filterConfig;
    const {additionalCriteria: {filters, additionalFilters} = {}} = selectedFilterData;
    const formattedDefaultSelectedValues = formatDefaultSelectedValues(defaultSelectedValues);
    const filterSelectedValues = !isEmpty(filters) ? filters : formattedDefaultSelectedValues;
    const additionalFiltersConfig = !isEmpty(additionalFilters) && additionalFilters;
    if (!isEmpty(filterAdditionalCriteria) && !isEmpty(additionalCriteria)) {
      return filterAdditionalCriteria
        .map((key) => {
          let firstItem;
          let restOfItems;
          let dropDownType = 'tree';
          let dropDownItemType = 'checkbox';
          const selectedKey = additionalFiltersServiceFieldMapper[key] || key;
          let selectedValues = filterSelectedValues[selectedKey] || (formattedDefaultSelectedValues[key] || []);
          if (additionalCriteria[key]) {
            if (additionalFiltersConfig) {
              const filteredItems = additionalFiltersConfig[key] || [];
              selectedValues = filteredItems.map(({value}) => value);
            }
            [firstItem, ...restOfItems] = additionalCriteria[key];
          } else if (reportingAdditionalFilterConfig[key]) {
            const {componentType, itemType = 'checkbox', items} = reportingAdditionalFilterConfig[key];
            dropDownType = componentType;
            dropDownItemType = itemType;
            [firstItem, ...restOfItems] = items;
          } else {
            return [];
          }

          // For Stitched reports
          if (isIncludeAdditionalFilterAsPayload && dropDownItemType!=='radio') {
            dropDownType = null;
            dropDownItemType = 'select';
          }

          let isFirstOptionSelected;
          if (isIncludeAdditionalFilterAsPayload || dropDownItemType === 'checkbox') {
            isFirstOptionSelected = (dropDownItemType !== 'radio') ? isAllItemsSelected(
              reportingAdditionalFilterConfig[key], selectedValues
            ) : isItemSelected(selectedValues, firstItem.code);
          } else {
            isFirstOptionSelected = isItemSelected(selectedValues, firstItem.code);
          }
          const items = [{
            value: firstItem.code,
            type: dropDownItemType,
            label: t(firstItem.description),
            selected: isFirstOptionSelected,
            nodeId: (dropDownType === 'tree') ? `all${key}` : `flatList${key}`,
            open: false,
          }];

          const selectRestOfItemsByDefault = (isEmpty(selectedValues) &&
            !isIncludeAdditionalFilterAsPayload);
          restOfItems && restOfItems.forEach(({
            code,
            description,
            fundAccounts,
            accountIds,
            shareclassIds
          }) => {
            items.push({
              value: code,
              type: dropDownItemType,
              label: t(description),
              selected: (selectRestOfItemsByDefault || isItemSelected(selectedValues, code)),
              parentId: `all${key}`,
              nodeId: `${code}${description}`,
              fundAccounts,
              accountIds,
              shareclassIds
            });
          });
          return {
            itemId: key,
            items,
            dropDownType
          };
        });
    }
    return [];
  }
);

export const getEntitledFirmBranchAccountFundCount = createDeepEqualSelector(
  entitlementSelector,
  (entitledData) => {
    const {nodeMap, scWithoutAcct} = entitledData;
    if (!isEmpty(nodeMap)) {
      const {
        firmNodeMap,
        branchNodeMap,
        taAccountNodeMap,
        shareclassNodeMap
      } = nodeMap;
      const {shareclassNodeMap: shareclassNodeWihtoutAcct} = scWithoutAcct;
      return {
        firmsCount: Object.keys(firmNodeMap).length,
        branchesCount: Object.keys(branchNodeMap).length,
        accountsCount: Object.keys(taAccountNodeMap).length,
        shareclassesCount: Object.keys(shareclassNodeMap).length,
        shareclassWithoutAcctCount: Object.keys(shareclassNodeWihtoutAcct).length
      };
    }
    return {
      firmsCount: [],
      branchesCount: [],
      accountsCount: [],
      shareclassesCount: [],
      shareclassWithoutAcctCount: []
    };
  }
);

export const filterDataSelector = createSelector(
  selectedFilterDataSelector,
  (filteredData) => {
    return filteredData;
  }
);

export const filterDataCountSelector = createSelector(
  selectedFilterDataSelector,
  (filteredData = {}) => {
    if (isEmpty(filteredData)) return filteredData;
    const {
      firmIds,
      branchIds,
      taAccountIds,
      shareclassIds,
      shareclassIdsWithoutAcct
    } = filteredData;
    return {
      firmsCount: firmIds.length,
      branchesCount: branchIds.length,
      accountsCount: taAccountIds.length,
      shareclassesCount: shareclassIds.length,
      shareclassIdsWithoutAcctCount: shareclassIdsWithoutAcct ? shareclassIdsWithoutAcct.length : 0
    };
  }
);

export const reportHeaderAndIFrameURLSelector = createSelector(
  currentReportConfigSelector,
  (currentReportConfig) => {
    return currentReportConfig && currentReportConfig.stitchedURL;
  }
);

export const currenciesSelector = createSelector(
  reportingAdditionalFilterConfigSelector,
  (reportingAdditionalFilterConfig) => {
    const {currencies} = reportingAdditionalFilterConfig;
    const list = [
      {
        value: '',
        type: 'checkbox',
        label: t('tkAllCurrencies'),
        selected: true,
        nodeId: 'allCurrencies',
        open: false,
      },
    ];
    currencies &&
    currencies.items.forEach(({code}) => {
      list.push({
        value: code,
        type: 'checkbox',
        label: t(`tk${code}`),
        selected: true,
        parentId: 'allCurrencies',
        nodeId: code,
      });
    });
    return list;
  }
);

export const currentReportAllColumnsSelector = createDeepEqualSelector(
  getCurrentGridView,
  getGroupByColumnsForCurrentView,
  (headers, groupbyFields) => {
    const columnsMapper = {};
    const {groupHeaders, groupHeaderIndexMap} = groupbyFields;
    const addColumnKey = (header) => {
      const {field, complexKeys} = header;
      if (field === COLUMN_COLID.GROUP) return;
      if (complexKeys) {
        columnsMapper[field] = {
          complexKeys,
          dataType: 'string'
        };
      } else {
        columnsMapper[field] = {
          key: field,
          dataType: header && header.dataType || 'string',
          type: header && header.type
        };
      }
    };
    headers.forEach((header) => {
      addColumnKey(header);
    });
    const groupByKeys = Object.keys(groupHeaderIndexMap);
    const columnKeys = Object.keys(columnsMapper);
    const diffKeys = difference(groupByKeys, columnKeys);
    diffKeys.forEach((columnKey) => {
      const groupIndex = groupHeaderIndexMap[columnKey];
      const grpHeaderObj = groupHeaders[groupIndex];
      addColumnKey(grpHeaderObj);
    });
    return columnsMapper;
  }
);

export const snackbarList = createDeepEqualSelector(
  labelSelector,
  hasMorePagesSelector,
  (labels, hasMorePages) => {
    const list = [];
    if (hasMorePages) {
      list.push({
        displayMultiple: true,
        type: 'warning',
        msgCopy: labels.tkCopyPort05,
        autoHide: false,
        showCloseCTA: true,
        id: 'hasMorePagesError'
      });
    }
    return list;
  }
);

export const getSelectedTaAccountAndAccountIds = ({
  accountMap,
  selected: {
    taAccountsSelected
  }
}) => {
  const selectedAccountIds = Object.keys(accountMap);
  const selectedTaAccounts = Object.keys(taAccountsSelected);
  return {
    taAccountIds: selectedTaAccounts,
    accountIds: selectedAccountIds
  };
};

export const isAllSelectedForEntity = ({
  selected = {},
  firmBranchValuesObj = {},
  taAccountValuesObj = {}
}) => {
  let isAllFirmBranchSelected = false;
  let isAllTaAccountSelected = false;
  if (selected.firmBranchSelected) {
    const {
      firmBranchSelected: {
        branchNodeMapSelected,
        firmNodeMapSelected
      }
    } = selected;
    const firmBranch = [];
    Object.values(firmBranchValuesObj).forEach((fb) => {
      const {value: firmId, children: branches = []} = fb;
      branches.forEach(({value: branchId}) => {
        firmBranch.push(branchId);
      });
      firmBranch.push(firmId);
    });
    const selectedFirmBranch = [...Object.keys(branchNodeMapSelected), ...Object.keys(firmNodeMapSelected)];
    isAllFirmBranchSelected = firmBranch.length === selectedFirmBranch.length;
  }
  if (selected.taAccountsSelected) {
    const { taAccountsSelected } = selected;
    isAllTaAccountSelected = Object.values(taAccountValuesObj).length === Object.keys(taAccountsSelected).length;
  }
  return {isAllFirmBranchSelected, isAllTaAccountSelected};
};

export const legacyReportPageIdSelector = createSelector(
  pageIdSelector,
  allReportsGridDataSelector,
  (pageId, allReportsGridData) => {
    const {legacyReportId} = allReportsGridData.find(({newReportId}) => (
      newReportId === pageId
    )) || {};
    return legacyReportId;
  }
);

export const isUserEntitledToOldReportSelector = createSelector(
  legacyReportPageIdSelector,
  reportingPermitRolesSelector,
  (legacyReportId, reportingPermitRoles) => {
    const legacyPermitRole = `${legacyReportId}.legacy`;
    return reportingPermitRoles.includes(legacyPermitRole);
  }
);

export const reportSwitchSnackBarMessageSelector = createDeepEqualSelector(
  pageIdSelector,
  isUserEntitledToOldReportSelector,
  isHideReportRollBackSnackBarMsgSelector,
  (pageId, isUserEntitledToOldReport, isHideReportRollBackSnackBarMsg) => {
    if (!isHideReportRollBackSnackBarMsg &&
      Object.keys(STANDARD_REPORT_VIEW_NAMES).includes(pageId) && isUserEntitledToOldReport) {
      return [{
        displayMultiple: true,
        type: 'note',
        message: t('tkNewReportSnackbarMsg'),
        autoHide: false,
        showCloseCTA: true,
        useCustomBody: true,
        id: 'isSwitchToOldReportSnackBarMsg',
        isReportRollBackSnackbar: true,
        affix: false
      }];
    }
    return [];
  }
);
