import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Highcharts from 'highcharts/highstock';
import moment from 'moment/moment';
import { cloneDeep, isEqual, memoize } from 'lodash';
import { FUND_TRACKER, DATA_TYPE, ADJUSTED_PREFIX, PLOTLINE_ID, REBATE_ELIGIBLE_METRICS } from '../constants';
import {addMetricToSeries, fetchFundsOrBenchmarksData, fetchAllPerformanceData} from '../services';
import {amountFormatter} from '../../../utils/amountFormatter';
import {
  isMetricDataMissing as isMetricDataMissingAction,
  setFieldForQuery as setFieldForQueryAction,
  setMetricBenchmarksToState as setMetricBenchmarksToStateAction,
  setShareClassPerformanceData,
  updateChartOptions as updateChartOptionsAction,
  updateShareClassList,
  isFundOrBenchmarkDataMissing as isFundOrBenchmarkDataMissingAction,
  updateChartOptionsItem as updateChartOptionsItemAction
} from '../store/fund-tracker-slice';
import { getPlotline } from '../../../components/core/Charts/helpers';
import translator from '../../../services/translator';

const { translate: t } = translator;

export const orderShareclassPerformance = (shareclassPerfResult, shareclassOrder, keys) => {
  if (isEmpty(keys)) return shareclassPerfResult;

  const keysArr = keys.split('.');
  const getValue = (data, keyArr, index = 0) => {
    if (keyArr.length === index) return data;

    const key = keyArr[index];
    const curValue = data[key];
    return getValue(curValue, keyArr, index + 1);
  };

  const shareclassNotFoundInOrderList = new Set();
  const sortFunc = (resultA, resultB) => {
    const valueA = getValue(resultA, keysArr);
    const valueB = getValue(resultB, keysArr);
    const indexA = shareclassOrder.indexOf(valueA);
    const indexB = shareclassOrder.indexOf(valueB);
    if (indexA === -1) shareclassNotFoundInOrderList.add(valueA);
    if (indexB === -1) shareclassNotFoundInOrderList.add(valueB);
    return indexA - indexB;
  };

  let orderedResult = cloneDeep(shareclassPerfResult);
  orderedResult.sort(sortFunc);

  if (shareclassNotFoundInOrderList.size > 0) {
    orderedResult = orderedResult.slice(shareclassNotFoundInOrderList.size);
  }
  return orderedResult;
};

/**
 * checks whether `current` and `previous` are equal objects. Returns true if they are different
 */
export const hasObjectChanged = (current, previous) => {
  return !isEqual(current, previous);
};

/**
 * Adjust the Chart Series Dash Style of other series if Adjusted Series found, with the following style
 * Adjusted Series Line Style - Solid
 * Adjusted Series Line Style - Shortline
 * @param chartSeriesData
 * @returns {*}
 */
export const adjustChartSeriesDashStyle = (chartSeriesData) => {
  const someAdjustedSeriesFound = chartSeriesData.some(item => item.isAdjusted);
  return chartSeriesData.map(item => {
    const changeUnadjustedSeriesDashStyle = (someAdjustedSeriesFound && !item.isAdjusted);
    return {
      ...item,
      dashStyle: changeUnadjustedSeriesDashStyle ? 'shortdash' : 'solid'
    };
  });
};

export const getHighChartSeries = (data, id = '', yAxisLabel = '', isSecondaryYAxis = false, isApplyRebates = false) => {
  let points;
  let longName;
  let category;
  const isBenchmarkProduct = (data.type === DATA_TYPE.BENCHMARK);

  // either data is a benchmark or a fund
  if (isBenchmarkProduct) {
    const {points: datapoints = [], name, category: benchmarkCategory} = data;
    points = datapoints;
    longName = name;
    category = benchmarkCategory;
  } else {
    const {points: datapoints = [], shareclass: {longName: scName} = {}} = data;
    points = datapoints;
    longName = scName;
  }

  let noData = true;
  let seriesPoint = points.filter(({isAdjYield}) =>
    (isApplyRebates && !isBenchmarkProduct) ? !!isAdjYield : !isAdjYield
  );
  // we want to return series even with no data points
  if (seriesPoint !== null) {
    // in theory we no longer need check for every y. Back-end is taking care of this.
    const hasNoDataForAnyDate = seriesPoint.every(({ y }) => !y);
    if (!hasNoDataForAnyDate) {
      seriesPoint = seriesPoint.reduce((result, point) => {
        const { x, y } = point;
        const time = new Date(x).getTime();
        if (point.y) {
          noData = false;
          result.push([time, Number(y)]);
        } else if (noData) {
          result.push([time, undefined]);
        }
        return result;
      }, []);

      // sort based on x-axis
      seriesPoint.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        } else if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
    } else {
      seriesPoint = [];
    }

    const secondaryYAxis = isSecondaryYAxis ? { yAxis: 1 } : {};
    const additionalConfig = yAxisLabel ? { ...secondaryYAxis, legendItem: { textStr: yAxisLabel } } : {};
    const dashStyle = isApplyRebates ? { dashStyle: 'solid', width: 1 } : {};
    return {
      name: yAxisLabel || longName || category,
      id,
      data: seriesPoint,
      // series will not show in legend if no data points
      showInLegend: seriesPoint.length > 0,
      noData,
      ...dashStyle,
      ...additionalConfig,
      showInNavigator: true,
      isAdjusted: isApplyRebates
    };
  }
  return null;
};

export const getAllHighChartSeries = (data, fieldValue = '', isApplyRebates, chartSeriesDataList = [], yAxisLabel = '') => {
  const seriesId = `${data.id}-${fieldValue}`;
  const {shareclassPerformance: {isRebateExists} = {}} = data;
  const regularMetricSeries = getHighChartSeries(
    data, seriesId, yAxisLabel, false, false
  );
  // If user opted to view Adjusted Yields
  if (isRebateExists && isApplyRebates) {
    const adjYieldSeries = getHighChartSeries(
      data, `${seriesId}-adj`, yAxisLabel, !yAxisLabel, true
    );
    // change the line style unadjusted yields series to Dashed Lines (Only if the Adjusted yield series is present)
    if (regularMetricSeries && adjYieldSeries) {
      regularMetricSeries.dashStyle = 'shortdash';
      regularMetricSeries.width = 1;
    }
    regularMetricSeries && chartSeriesDataList.push(regularMetricSeries);
    if (adjYieldSeries) {
      adjYieldSeries.name = `${adjYieldSeries.name} (${t('tkAdjusted')})`;
      adjYieldSeries.color = regularMetricSeries.color; // Set the Adjusted series color same as Unadjusted series
      chartSeriesDataList.push(adjYieldSeries);
    }
  } else if (regularMetricSeries) { // Just add the regular series to the chart
    regularMetricSeries.dashStyle = 'solid';
    regularMetricSeries.width = 1;
    chartSeriesDataList.push(regularMetricSeries);
  }
};

export const getAsOfDate = (data) => {
  const presentDate = moment(new Date()).format('YYYY-MM-DD');
  if(data.length) {
    for(let i = data.length-1; i>=0; i--){
      if(data[i][1]) return moment(new Date(data[i][0])).format('YYYY-MM-DD');
    }
  }
  return presentDate;
};

// Entries which are present in currentArray and not in prevArray.
const diffArrays = (curArray, prevArray, compareFunction) => {
  return curArray.filter(curValue =>
    !prevArray.some(prevValue => compareFunction(curValue, prevValue)));
};
export const getNormalizedGridData = (shareClassPerformanceData) => (
  shareClassPerformanceData.map((item) => {
      const {
        nav: {
          nav,
          mtmNav
        } = {},
        assets: {
          totalFundAssets,
          dailyNetShareholderFlowsPercent,
          dailyNetShareholderFlows
        } = {},
        liquidity: {
          liquidAssetsDaily,
          liquidAssetsWeekly
        } = {},
        duration: {
          wal,
          wam
        } = {},
        name,
        identifiers: {
          cusip,
          isin
        } = {},
        performance: {
          dailyFactor,
          yield1Day,
          yield7DayCurrent,
          yield7DayEffective,
          yield30Day,
          adjYield1Day,
          adjYield7DayCurrent,
          adjYield7DayEffective,
          adjYield30Day
        } = {}
      } = item;

      return {
        shareClassName: name,
        nav: nav && nav.amount || '--',
        mtmNav: mtmNav && mtmNav.amount|| '--',
        dailyFactor: dailyFactor || '--',
        yield1Day: yield1Day || '--',
        curYield7Day: yield7DayCurrent || '--',
        effYield7Day: yield7DayEffective || '--',
        yield30Day: yield30Day || '--',
        adjYield1Day: adjYield1Day || '--',
        adjYield7DayCurrent: adjYield7DayCurrent || '--',
        adjYield7DayEffective: adjYield7DayEffective || '--',
        adjYield30Day: adjYield30Day || '--',
        liquidAssetsDaily: liquidAssetsDaily || '--',
        liquidAssetsWeekly: liquidAssetsWeekly || '--',
        wam: wam || '--',
        wal: wal || '--',
        totalFundAssets: totalFundAssets && `${amountFormatter(totalFundAssets.amount, null, true)} ${totalFundAssets.currency}` || '--',
        dailyNetShareholderFlowsPercent : dailyNetShareholderFlowsPercent || '--',
        dailyNetShareholderFlows: dailyNetShareholderFlows && amountFormatter(dailyNetShareholderFlows.amount, null, true) || '--',
        cusip: cusip || '--',
        isin: isin || '--'
      };
    }
  )
  );

export const getRecentShareclassAndBenchmarkIds = (comparisonProducts = [], primaryProduct = {}) => {
  const { BENCHMARK } = DATA_TYPE;

  const lastShareclassIds = [];
  const lastBenchmarkVendorIds = [];

  if (primaryProduct?.value) {
    // add primary product to the correct product type list
    if (primaryProduct.type === BENCHMARK) {
      lastBenchmarkVendorIds.push(primaryProduct?.value);
    } else {
      lastShareclassIds.push(primaryProduct.value);
    }
  }

  comparisonProducts.forEach(({ value, type }) => {
    if (type === BENCHMARK) {
      lastBenchmarkVendorIds.push(String(value));
    } else {
      lastShareclassIds.push(String(value));
    }
  });

  return { lastShareclassIds, lastBenchmarkVendorIds };
};

/**
 * @param {Array} shareclasses
 * @param {string} asOfDate
 * @returns list of two items: 1st has payload, 2nd has ordered shareclasses ids
 */
export const getShareclassPerformancePayload = (shareclasses = [], asOfDate) => {
  if (!shareclasses.length) {
    return {};
  }
  const cusips = [];
  const isins = [];
  const orderedShareclassIds = [];

  shareclasses.forEach(({ cusip, isin, id }) => {
    if (cusip) {
      cusips.push(cusip);
    } else if (isin) {
      isins.push(isin);
    }
    orderedShareclassIds.push(id);
  });

  const shareclassPayload = {
    include: 'all',
    asOfDate,
    ...(cusips.length > 0 ? { cusip: cusips.join(',') } : {}),
    ...(isins.length > 0 ? { isin: isins.join(',') } : {})
  };

  return { shareclassPayload, orderedShareclassIds };
};

export const setMetricBenchmarksToState = (dispatch, data) => {
  dispatch(setMetricBenchmarksToStateAction(data));
};

export const updateShareClassListToState = (dispatch, allShareClassData) => {
  dispatch(
    updateShareClassList({
      allShareClassData,
      replaceShareClassData: true
    })
  );
};

export const updateChartOptions = (dispatch, updateChartOptions) => {
  dispatch(updateChartOptionsAction({
    chartOptions: updateChartOptions
  }));
};

export const updateShareclassPerformanceData = (dispatch, { results, asOfDate }) => (
  dispatch(setShareClassPerformanceData({results, asOfDate}))
);

export const setFieldForQuery = (dispatch, defaultMetricItem = {}) => {
  const {label: fieldLabel, value: fieldValue} = defaultMetricItem;
  dispatch(
    setFieldForQueryAction({
      fieldForQuery: {
        fieldLabel,
        fieldValue
      }
    })
  );
};

export const clearDataMissingMessages = dispatch => {
  dispatch(
    isMetricDataMissingAction({
      isMetricDataMissing: false
    })
  );
};

export const removeChartSeriesColor = (chartInstance, id) => {
  const chart = chartInstance?.get(id);
  if (chart) {
    chart.remove(true);
  }
};

export const getBenchmarkFieldValue = (benchmarkMetricsOptions, field) => {
  const {benchmarkMetricOption} = benchmarkMetricsOptions.find(({defaultMetricOption}) =>
    defaultMetricOption === field
  ) || {};
  return benchmarkMetricOption;
};

export const removeFundOrBenchmarkSeriesFromChart = (
  fundOrBenchmarksToRemove, fieldValue, chartInstance, chartOptions, isApplyRebates = false
) => {
  const fundOrBenchmarksIdList = [];
  fundOrBenchmarksToRemove.forEach(({value}) => {
    fundOrBenchmarksIdList.push(`${value}-${fieldValue}`);
    isApplyRebates && fundOrBenchmarksIdList.push(`${value}-${fieldValue}-adj`);
  });
  // Remove the colors from chart
  fundOrBenchmarksIdList.forEach(identifier =>
    removeChartSeriesColor(chartInstance, identifier)
  );
  // Remove series from chart options
  const {series} = chartOptions;
  // return the items that are not matched with list of items to be removed
  const updatedSeries = series.filter(item => {
    const someMatchFound = fundOrBenchmarksIdList.some(identifier => {
      return item.id === identifier;
    });
    return !someMatchFound;
  });
  return {
    ...chartOptions,
    series: updatedSeries
  };
};

export const removeMetricSeriesFromChartOptions = (
  chartInstance, chartOptions, identifier, yAxisLabel = '', isApplyRebates
) => {
  const identifiers = [identifier];
  isApplyRebates && identifiers.push(`${identifier}-adj`);
  // Remove series from chart
  removeChartSeriesColor(chartInstance, identifier);
  // Update the series and yAxis
  const {series, yAxis} = chartOptions;
  const updateSeries = series && series.filter(item => (
    !identifiers.includes(item.id)
  ));
  const updateYAxis = yAxis && isArray(yAxis) ? yAxis.filter(({title: {text}}) => (
    text !== yAxisLabel
  )) : yAxis;
  return {
    ...chartOptions,
    series: updateSeries,
    yAxis: updateYAxis
  };
};

export const updateChartSeries = (
  metricsItemToRemove,
  fundOrBenchmarksToRemove,
  chartState,
  fieldValue
) => {
  const {
    chartOptions,
    chartInstance,
    primaryProduct: { value: primaryProductId = '' },
    isApplyRebates
  } = chartState;
  if (metricsItemToRemove) {
    const {label, value} = metricsItemToRemove;
    const identifier = `${primaryProductId}-${value}`;

    // Remove metrics From chart options
    const updatedChartOptions = removeMetricSeriesFromChartOptions(
      chartInstance, chartOptions, identifier, label, isApplyRebates
    );
    // Remove benchmarks From chart options
    return removeFundOrBenchmarkSeriesFromChart(
      fundOrBenchmarksToRemove,
      fieldValue,
      chartInstance,
      updatedChartOptions,
      isApplyRebates
    );
  } else {
    return removeFundOrBenchmarkSeriesFromChart(
      fundOrBenchmarksToRemove,
      fieldValue,
      chartInstance,
      chartOptions,
      isApplyRebates
    );
  }
};

export const getUpdateSeriesWithNameAndLegend = (
  series, allShareClassData, primaryProduct, selectedMetrics = [], isApplyRebates = false
) => {
  let updateNameAndLegendData = {};
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  const {label: primaryMetricLabel, value: primaryMetricField} = primaryMetricItem || {};
  const updateType = compareMetricItem ? FUND_TRACKER.METRICS : FUND_TRACKER.BENCHMARKS;
  if (compareMetricItem) {
    // Update primary series name and legend with primary metric item
    updateNameAndLegendData = {
      name: primaryMetricLabel,
      legendItem: { textStr: primaryMetricLabel }
    };
  } else {
    // Reset the primary series name and legend with Primary Shareclass details
    const primaryProductData = allShareClassData.filter(({ id }) => id === primaryProduct.value)[0];
    if (primaryProductData && primaryProductData.type !== DATA_TYPE.BENCHMARK) {
      const {
        shareclass: { longName: shareClassName }
      } = primaryProductData;
      updateNameAndLegendData = {
        name: shareClassName,
        legendItem: { textStr: shareClassName }
      };
    } else {
      updateNameAndLegendData = {
        name: primaryProductData?.name,
        legendItem: { textStr: primaryProductData?.name }
      };
    }
  }
  return series.map(item => {
    const [id, field, adjYieldId] = item.id.split('-');
    const updateLegendFlag = ((updateType === FUND_TRACKER.METRICS) && (field === primaryMetricField)) ||
      ((updateType === FUND_TRACKER.BENCHMARKS) && (id === primaryProduct.value));
    if (updateLegendFlag) {
      if (isApplyRebates) {
        const suffix = adjYieldId ? ` (${t('tkAdjusted')})` : '';
        const legendText = `${updateNameAndLegendData.legendItem.textStr}${suffix}`;
        return {
          ...item,
          ...{
            ...updateNameAndLegendData,
            name: legendText,
            legendItem: {
              textStr: legendText
            }
          }
        };
      } else {
        return {
          ...item,
          ...updateNameAndLegendData
        };
      }
    }
    return item;
  });
};

export const shareClassFound = (data, shareClassList) => {
  return shareClassList.some(item => (data.id === item.id));
};

export const isChartSeriesDataEmpty = (chartSeriesData) => {
  return chartSeriesData && chartSeriesData.data
    && isEmpty(chartSeriesData.data);
};

export const isYAxisAlreadyExists = (updatedChartOptions, label) => {
  if (updatedChartOptions.yAxis && isArray(updatedChartOptions.yAxis)) {
    return updatedChartOptions.yAxis.some(({title: {text}} = {}) => {
      return text === label;
    });
  }
  return false;
};

/**
 * When we have fetched chart data to be plotted on fund tracker, we need to do some processing
 * and this util takes care of the right data processing and manipulation
 */
export const processAllChartDataResponse = ({
  allShareClassData,
  updatedChartOptions,
  yAxisLabel,
  completeData,
  fieldValue,
  addShareClassData,
  chartDispatch,
  primaryProduct,
  selectedMetrics,
  selectedFundOrBenchmarks,
  isApplyRebates = false,
}) => {
  const shareClassesData = [];
  let updatedChartOptionsClone = {...updatedChartOptions};
  let clonseShareclassData;
  // This would be, only if we change metrics from Dropdown above chart
  if (yAxisLabel && !isYAxisAlreadyExists(updatedChartOptions, yAxisLabel)) {
    isArray(updatedChartOptions.yAxis) && updatedChartOptions.yAxis.push({
      title: {
        text: yAxisLabel
      },
      opposite: true,
      offset: 40
    });
  }

  completeData.forEach((data = {}) => {
    // Add the series to chart options
    const chartKey = `${data.id}-${fieldValue}`;
    updatedChartOptionsClone.series = updatedChartOptionsClone.series.filter(({id}) => id !== chartKey);
    getAllHighChartSeries(data, fieldValue, isApplyRebates, updatedChartOptionsClone.series);

    // Collect the shareclass data (only if we are navigating from Compare Modal)
    if (addShareClassData && !shareClassFound(data, allShareClassData)) {
      shareClassesData.push(data);
    }
  });

  // Adjust line dashStyle based on Adjusted Series
  const updatedSeries = adjustChartSeriesDashStyle(updatedChartOptionsClone.series);
  updatedChartOptionsClone = {
    ...updatedChartOptionsClone,
    series: updatedSeries
  };

  if (selectedFundOrBenchmarks) {
    const shareClassOrder = selectedFundOrBenchmarks.reduce((initial, item) => {
      const identifier = `${item.value}-${fieldValue}`;
      initial.push(identifier);
      // If we find adjusted yield series, push that id as well
      if (isApplyRebates) {
        const adjSeriesId = `${identifier}-adj`;
        const isAdjYieldSeriesFound = updatedChartOptionsClone.series.some(({id}) =>
          id === adjSeriesId
        );
        isAdjYieldSeriesFound && initial.push(adjSeriesId);
      }
      return initial;
    }, []);
    updatedChartOptionsClone.series = orderShareclassPerformance(
      updatedChartOptionsClone.series, shareClassOrder, 'id'
    );
  }

  const {series} = updatedChartOptionsClone;
  const seriesShareclassId = series.map(series => series.id.split('-')[0]);
  const perfShareclasses = allShareClassData.filter(sc => {
    return seriesShareclassId.indexOf(sc.id) !== -1;
  });
  clonseShareclassData = [...perfShareclasses, ...completeData];
  // Remove duplicates shareclasses
  clonseShareclassData = clonseShareclassData.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);

  // Add the shareclass data to the list (to Show it in bottom grid)
  chartDispatch(updateShareClassList({
    allShareClassData: clonseShareclassData,
    replaceShareClassData: true
  }));

  // Update the Chart Options to the state
  chartDispatch(updateChartOptionsAction({
    chartOptions: {
      ...updatedChartOptionsClone,
      series: getUpdateSeriesWithNameAndLegend(
        updatedChartOptionsClone.series,
        clonseShareclassData,
        primaryProduct,
        selectedMetrics,
        isApplyRebates
      )
    }
  }));

  // Check if data missing for some Funds or Benchmarks
  const isFundOrBenchmarkDataMissing = isEmpty(updatedChartOptionsClone.series) || updatedChartOptionsClone.series.some(data =>
    isChartSeriesDataEmpty(data)
  );
  chartDispatch(
    isFundOrBenchmarkDataMissingAction({
      isFundOrBenchmarkDataMissing
    })
  );

  if (addShareClassData && shareClassesData.length > 0) {
    const asOfDate = series[0]?.data ? getAsOfDate(series[0]?.data) : '';
    const processedBenchmarks = clonseShareclassData.filter(
      ({ type }) => type === DATA_TYPE.BENCHMARK
    );
    const shareclasses = clonseShareclassData
      .filter(({ shareclass }) => shareclass != null)
      .map(({ shareclass }) => shareclass);
    return {
      asOfDate,
      shareclasses,
      processedBenchmarks
    };
  }
  return {};
};

// ************************************************************************************
//                       Fund/Benchmark OR Metrics series
// ************************************************************************************

// ********************************************************************************************
//    This determines if the Metric series already exits on the chart, this is useful to
//    to determine if we need to add the selected Fund/Benchmark when user switches the tabs
// ********************************************************************************************
export const isMetricsSeriesAlreadyExists = (compareMetricItem, chartOptions) => {
  const {label} = compareMetricItem;
  if (chartOptions && chartOptions.series && chartOptions.series.length > 0) {
    return chartOptions.series.some(item => item.name === label);
  }
  return true;
};

// **************************************************************************************************
//    This determines if the Fund/Benchmark series already exits on the chart, this is useful to
//    to determine if we need to add the selected Fund/Benchmark when user switches the tabs
// **************************************************************************************************

export const isBenchmarksSeriesAlreadyExists = (chartOptions, selectedFundsOrBenchMarks) => {
  if (chartOptions && chartOptions.series) {
    const itemsWithData = selectedFundsOrBenchMarks;
    if (chartOptions.series.length > 0) {
      if (itemsWithData.length > 0) {
        const benchmarksSeriesAlreadyExists = itemsWithData.every(benchmark => {
          const itemExistsInChartSeries = chartOptions.series.some(item =>
            item.name === benchmark.label
          );
          return itemExistsInChartSeries;
        });
        return benchmarksSeriesAlreadyExists;
      }
    } else {
      return !itemsWithData.length;
    }
  }
  return true;
};

export const addFundsOrBenchmarkSeriesToChart = (
  run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  primaryMetricItem,
  allSelectedShareclasses
) => {
  if (!isBenchmarksSeriesAlreadyExists(chartOptions, selectedFundsOrBenchMarks)) {
    fetchFundsOrBenchmarksData(
      run,
      fieldForQuery,
      benchmarkMetricsOptions,
      selectedFundsOrBenchMarks,
      chartDispatch,
      chartOptions,
      chartState,
      true,
      '',
      [primaryMetricItem],
      allSelectedShareclasses
    );
  } else {
    // Just update the Chart Options to the state, as there may be an update to it
    chartDispatch(updateChartOptionsAction({
      chartOptions
    }));
  }
};

export const refreshFundsOrBenchmarkSeriesDataOnChart = (
  completeData,
  _run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  primaryMetricItem
) => {
  if (!isBenchmarksSeriesAlreadyExists(chartOptions, selectedFundsOrBenchMarks)) {
    const {allShareClassData, primaryProduct} = chartState;
    const {fieldValue} = fieldForQuery;
    processAllChartDataResponse({
      addShareClassData: true,
      allShareClassData,
      benchmarkFieldValue: getBenchmarkFieldValue(benchmarkMetricsOptions, fieldValue),
      chartDispatch,
      primaryProduct,
      selectedMetrics: [primaryMetricItem],
      completeData,
      fieldValue,
      updatedChartOptions: chartOptions,
      yAxisLabel: ''
    });
  } else {
    // Adjust the Chart Series Dash Style of other series if Adjusted Series found
    const updatedChartSeries = adjustChartSeriesDashStyle(chartOptions.series);
    const updatedChartOptions = {
      ...chartOptions,
      series: updatedChartSeries
    };
    chartDispatch(
      updateChartOptionsAction({
        chartOptions: updatedChartOptions
      })
    );
  }
};

export const addMetricsSeriesToChart = (
  compareMetricItem,
  run,
  chartDispatch,
  chartOptions,
  chartState,
  selectedMetrics
) => {
  if (!isMetricsSeriesAlreadyExists(compareMetricItem, chartOptions)) {
    addMetricToSeries(
      compareMetricItem,
      run,
      chartDispatch,
      chartOptions,
      chartState,
      selectedMetrics,
      false
    );
  }
};

export const getFundDisplay = (data = {}, fundIdentifier) => {
  if (!data.shareclass) return '';
  const { shareclass } = data;
  switch(fundIdentifier) {
    case 'CUSIP': return shareclass.cusip;
    case 'ISIN': return shareclass.isin;
    case 'FUND_NUMBER': return shareclass.shareClassDetails.displayCode;
    case 'TICKER': return shareclass.nasdaqTicker;
    default: return '';
  }
};

export const getSelectedShareClassIds = (primaryProduct, isBenchMarksCompareMode, selectedFundsOrBenchMarks) => {
  const listWithPrimaryShareClass = primaryProduct && primaryProduct.value ? [primaryProduct.value] : [];
  if (isBenchMarksCompareMode) {
    const compareShareClsList = selectedFundsOrBenchMarks
      .filter(data => data.type === DATA_TYPE.FUND)
      .map(({value}) => value);
    return [...listWithPrimaryShareClass, ...compareShareClsList];
  }
  return listWithPrimaryShareClass;
};

export const getThresholdsForSelectedFunds = (
  primaryProduct, isBenchMarksCompareMode,
  selectedFundsOrBenchMarks, metricEnum, thresholdList
) => {
  const selectedShareClassIds = getSelectedShareClassIds(
    primaryProduct, isBenchMarksCompareMode,
    selectedFundsOrBenchMarks
  );
  return thresholdList
    .filter(({metric, shareClassId}) => (
      (metric === metricEnum) && selectedShareClassIds.includes(shareClassId)
    ));
};

const getThresholdItem = (thresholdList, metricEnum, selectedShareClassId, isApplyRebates) => {
  const updatedMetricEnum = `${isApplyRebates ? ADJUSTED_PREFIX : ''}${metricEnum}`;
  return thresholdList.find(({metric, shareClassId}) => (
    (metric === updatedMetricEnum) && (shareClassId === selectedShareClassId)
  ));
};

export const getAllThresholdsForSelectedFunds = (
  primaryProduct, isBenchMarksCompareMode, selectedFundsOrBenchMarks,
  selectedMetrics, thresholdList, isApplyRebates
) => {
  const [{enum: primaryMetricEnum} = {}] = selectedMetrics;
  const selectedShareClassIds = getSelectedShareClassIds(
    primaryProduct, isBenchMarksCompareMode, selectedFundsOrBenchMarks
  );
  return selectedShareClassIds
    .map(shareClassId => getThresholdItem(
      thresholdList, primaryMetricEnum, shareClassId, isApplyRebates
    ))
    .filter(item => item);
};

export const verifyThresholds = (
  primaryProduct,
  selectedFundsOrBenchMarks,
  selectedMetrics,
  thresholdList,
  isBenchMarksCompareMode,
  isApplyRebates
) => {
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  if (primaryProduct?.value && primaryMetricItem && !compareMetricItem && thresholdList.length > 0) {
    // Get the lower and upper thresholds for the Primary Fund Selected
    const primaryThresholdItem = getThresholdItem(
      thresholdList, primaryMetricItem.enum, primaryProduct.value, isApplyRebates
    );
    // If the primary threshold item has the threshold set
    if (primaryThresholdItem) {
      const {
        metric: primaryMetricEnum,
        lowerThreshold: baseLowerThreshold,
        upperThreshold: baseUpperThreshold,
        emailNotificationEnabled
      } = primaryThresholdItem;
      // If only Primary fund is selected, check if the Primary Fund has threshold set
      if (!isBenchMarksCompareMode) {
        return {
          isExistingThresholdItem: true,
          isAllThresholdHasSameLimits: true,
          baseLowerThreshold,
          baseUpperThreshold,
          emailNotificationEnabled
        };
      } else {
        const thresholdsForSelectedFunds = getThresholdsForSelectedFunds(
          primaryProduct,
          isBenchMarksCompareMode,
          selectedFundsOrBenchMarks,
          primaryMetricEnum,
          thresholdList
        );
        // Add one (primary fund which is selected on landing page) to the compare funds (selected in compare modal)
        const totalFundsOrBenchmarksSelected = (selectedFundsOrBenchMarks.length + 1);
        // If we are in Funds/Benchmark comparison mode, then see if Number of thresholds equals to the number of Funds/Benchmark selected
        if (thresholdsForSelectedFunds.length === totalFundsOrBenchmarksSelected) {
          // Check if all the thresholds are same
          const isAllThresholdHasSameLimits = thresholdsForSelectedFunds.every(({lowerThreshold, upperThreshold}) => {
            return (lowerThreshold === baseLowerThreshold) && (upperThreshold === baseUpperThreshold);
          });
          // If all the thresholds are same, return the threshold values to populate it in Threshold Modal
          if (isAllThresholdHasSameLimits) {
            return {
              isExistingThresholdItem: true,
              isAllThresholdHasSameLimits,
              baseLowerThreshold,
              baseUpperThreshold,
              emailNotificationEnabled,
            };
          }
        }
        return {
          isExistingThresholdItem: true,
          isAllThresholdHasSameLimits: false
        };
      }
    }
  }
  return {
    isExistingThresholdItem: false,
    isAllThresholdHasSameLimits: true
  };
};

export const isAllThresholdsEmpty = (
  primaryProduct, isBenchMarksCompareMode,
  selectedFundsOrBenchMarks, selectedMetrics, thresholdList
) => {
  const [{enum: primaryMetricEnum} = {}] = selectedMetrics;
  const thresholdsForSelectedFunds = getThresholdsForSelectedFunds(
    primaryProduct, isBenchMarksCompareMode,
    selectedFundsOrBenchMarks, primaryMetricEnum, thresholdList
  );
  return thresholdsForSelectedFunds.every(({lowerThreshold, upperThreshold}) => {
    return (!lowerThreshold && !upperThreshold);
  });
};

// ***************************************************************************************
//            To clean up the grid data after removing series from chart
// ***************************************************************************************
export const cleanupGridData = (
  selectedFundOrBenchmarksData = [],
  fundOrBenchmarksToRemove,
  allShareClassData,
  primaryProduct,
  chartDispatch
) => {
  /* If all the selected items were removed, get the default share class
     item added to the grid data */
  if (selectedFundOrBenchmarksData && !selectedFundOrBenchmarksData.length && allShareClassData.length > 1) {
    const initialShareClassData = allShareClassData.filter(item =>
      item.id === primaryProduct.value
    );
    updateShareClassListToState(chartDispatch, initialShareClassData);
  } else {
    /* Remove the benchmark data from the Grid Data list as well,
       if any share class was removed from modal */
    const shareClassDataForGrid = diffArrays(allShareClassData, fundOrBenchmarksToRemove, (v1, v2) => {
      return v1.id === v2.value;
    });
    updateShareClassListToState(chartDispatch, shareClassDataForGrid);
  }
};

export const getShareclassesAndBenchmarks = combineData => {
  const initialResult = { benchmarks: [], shareclasses: [] };
  return combineData.reduce((result, data) => {
    if (data.type === DATA_TYPE.BENCHMARK) {
      result.benchmarks.push(data);
    } else if (data.type === DATA_TYPE.FUND) {
      result.shareclasses.push(data.shareclass);
    }
    return result;
  }, initialResult);
};

export const addPlotLinesAndMarker = (e, allShareClassData, run, chartDispatch, chartOptions) => {
  const myPlotLineId = PLOTLINE_ID;
  const {point} = e;
  const xValue = e.point.x;
  const {xAxis} = e.point.series;
  const xDate = Highcharts.dateFormat('%Y-%m-%d', xValue);
  const labelDate = Highcharts.dateFormat('%d.%B.%Y', xValue);
  const benchmarks = allShareClassData.filter(data => data.type === DATA_TYPE.BENCHMARK);
  const shareClassPerformanceData = allShareClassData
  .filter(({ shareclass }) => shareclass != null)
  .map(({ shareclass }) => shareclass);

  if (xAxis.plotLinesAndBands.length === 0) {
    const plotLine = getPlotline({labelDate, value: xValue, id: myPlotLineId, point});
    xAxis.addPlotLine(plotLine);
    fetchAllPerformanceData({
      asOfDate: xDate,
      shareclasses: shareClassPerformanceData,
      benchmarks,
      run,
      chartDispatch
    });
  } else {
    const prevValue = xAxis.plotLinesAndBands[0].options.value;
    if (xValue === prevValue) {
      try {
        xAxis.removePlotLine(myPlotLineId);
      } catch (err) {}
      const asOfDate = getAsOfDate(chartOptions.series[0].data);
      fetchAllPerformanceData({ asOfDate, shareclasses: shareClassPerformanceData, benchmarks, run, chartDispatch });
    } else {
      try {
        xAxis.removePlotLine(myPlotLineId);
      } catch (err) {}
      xAxis.addPlotLine({
        point,
        value: xValue,
        width: 1,
        dashStyle: 'dash',
        color: 'black',
        id: myPlotLineId,
        label: {
          useHTML: true,
          text: `<div style='color: black; background: lightgrey; text-align: center'>${labelDate} <br/> ${point.series.name}: ${point.y}</div>`,
          rotation: 0,
          align: 'center',
          verticalAlign: 'bottom'
        }
      });
      fetchAllPerformanceData({
        asOfDate: xDate,
        shareclasses: shareClassPerformanceData,
        benchmarks,
        run,
        chartDispatch
      });
    }
  }
};
export const allShareClassPerformanceData = (allShareClassData, fundOrBenchmarksToRemove) => {
  const shareClassDataForGrid = diffArrays(allShareClassData, fundOrBenchmarksToRemove, (v1, v2) => {
    return v1.id === v2.value;
  });
  return shareClassDataForGrid.map(data => {
    return data.shareclass;
  });
};

export const updateShareClassDetails = (items, shareClasses) =>
  items.map(item => {
    if (!item.label) {
      const matchedItem = shareClasses.find(({ id }) => id === item.value);
      if (matchedItem) {
        if (matchedItem.type === DATA_TYPE.BENCHMARK) {
          return {
            ...item,
            label: matchedItem.name
          };
        }
        const { longName: label, cusip, isin } = matchedItem.shareclass;
        return { ...item, label, cusip, isin };
      }
    }
    return item;
  });

export const getChartZoomLevel = (zoomPreset) => {
  const ranges = ['1m', '3m', '6m', 'YTD', '1y', 'All'];
  return ranges.indexOf(zoomPreset);
};

export const prepareSearchOption = (searchItem) => {
  // get all info needed for benchmark. Override them if it is a fund
  const { id: value, name, cusip, isin } = searchItem;
  let label = name;

  if (searchItem.type === DATA_TYPE.FUND) {
    const { longName, nasdaqTicker, taInfo: { gtamTaId } = {}, shareClassDetails = {}, fundDetails = {} } = searchItem;
    label = longName;

    if (
      fundDetails.fundType &&
      fundDetails.fundType.toLowerCase() === 'moneymarket' &&
      fundDetails.status &&
      fundDetails.status.toLowerCase() === 'open'
    ) {
      if (gtamTaId === 1) {
        label = `${longName} - ${shareClassDetails.currencyCode} | ${nasdaqTicker ? `${nasdaqTicker} | ` : ''}
        ${cusip ? `${cusip} | ` : ''}
        ${shareClassDetails.displayCode ? `${shareClassDetails.displayCode}` : ''}`;
      } else {
        label = `${longName} - ${shareClassDetails.currencyCode} |
        ${isin ? `${isin} | ` : ''}
        ${shareClassDetails.displayCode ? `${shareClassDetails.displayCode}` : ''}`;
      }
    } else {
      // preserve the case of the else block here by returning empty object
      return {};
    }
  }


  return {
    value,
    label,
    customProperties: {
      type: searchItem.type || DATA_TYPE.FUND,
      // add available cusip/isin when fund
      ...(searchItem.type === DATA_TYPE.FUND && (cusip || isin) ? { cusip, isin } : {}),
    }
  };
};

export const getFundValueToSearch = memoize((shareclass) => {
  // Prepare the plain list of Share Classes
  const {
    id: value,
    longName,
    isin,
    cusip,
    nasdaqTicker,
    taInfo: { gtamTaId } = {},
    shareClassDetails = {},
    fundDetails = {}
  } = shareclass;
  let label = longName;
  if (fundDetails.fundType && fundDetails.fundType.toLowerCase() === 'moneymarket' && fundDetails.status && fundDetails.status.toLowerCase() === 'open' ) {
    if (gtamTaId === 1) {
      label = `${longName} - ${shareClassDetails.currencyCode} | ${nasdaqTicker ? `${nasdaqTicker} | ` : ''}${cusip ? `${cusip} | ` : ''}${shareClassDetails.displayCode || ''}`;
    } else {
      label = `${longName} - ${shareClassDetails.currencyCode} | ${isin ? `${isin} | ` : ''}${shareClassDetails.displayCode || ''}`;
    }

    // possible that after currency code, nothing else shows, so we remove the last pipe
    label = label.trim().replace(/ \|$/, '');

    return {
      value,
      label,
      customProperties: {
        type: 'fund',
        cusip,
        isin
      }
    };
  }
  return {};
});

export const getSelectedFilterMap = (shareclasses, fieldValue) => {
  const filterShareclassFieldIdObj = shareclasses.reduce((finalObj, item) => {
    let key = item.value;
    if (fieldValue) {
      key += `-${fieldValue}`;
    }
    finalObj[key] = true;
    return finalObj;
  }, {});
  return filterShareclassFieldIdObj;
};


const filterData = (data, shareclasses, fieldValue) => {
  const filterShareclassFieldIdObj = getSelectedFilterMap(shareclasses, fieldValue);
  const filteredData = data.filter(d => filterShareclassFieldIdObj[d.id]);
  return filteredData;
};

export const filterShareclassData = (
  { chartOptions, allShareClassData, shareClassPerformanceData, fieldForQuery },
  shareclasses
) => {
  const { fieldValue } = fieldForQuery;
  const series = filterData(chartOptions.series, shareclasses, fieldValue);
  const filteredAllShareClassData = filterData(allShareClassData, shareclasses);
  const filteredShareClassPerformanceData = filterData(shareClassPerformanceData, shareclasses);
  return {
    series,
    allShareClassData: filteredAllShareClassData,
    shareClassPerformanceData: filteredShareClassPerformanceData
  };
};

export const hasTopHoldingsShareclasses = (topHoldingShareclasses, currentShareclasses) => {
  const currentShareclassMap = getSelectedFilterMap(currentShareclasses);

  let hasShareclasses = true;
  topHoldingShareclasses.every(({value}) => {
    if (!currentShareclassMap[value]) {
      hasShareclasses = false;
      return false;
    }
    return true;
  });

  return hasShareclasses;
};

export const updateChartOptionsItems = (dispatch, newUpdates) => {
  dispatch(updateChartOptionsItemAction(newUpdates));
};

/**
 * given selected metrics enum and chart options, fn helps remove the fund items
 * with rebates from the series to plot.
 */
export const removeRebateFunds = (metricsField, chartOptions = {}) => {
  const { series = [] } = chartOptions;
  if (metricsField && !REBATE_ELIGIBLE_METRICS.includes(metricsField) && series.length > 0) {
    return series
      .filter(({ isAdjusted }) => !isAdjusted)
      .map(item => ({
        ...item,
        dashStyle: 'solid'
      }));
  }
  return null;
};

export {
  diffArrays
};
